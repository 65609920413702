import { createTheme } from "@material-ui/core/styles";

import { colorConstants, gradientConstants } from "./constants";

export default createTheme({
  palette: {
    common: {
      black: `${colorConstants.black}`,
      white: `${colorConstants.white}`,
      lightGray: `${colorConstants.lightGray}`,
      gray90: `${colorConstants.gray90}`,
      gray70: `${colorConstants.gray70}`,
      gray10: `${colorConstants.gray10}`,
      danger: `${colorConstants.red}`,
      warning: `${colorConstants.orange}`,
      success: `${colorConstants.green}`,
      brandGradient: `${gradientConstants.brandGradient}`,
    },
    primary: {
      main: `${colorConstants.teal}`,
    },
    secondary: {
      main: `${colorConstants.blue}`,
    },
    background: {
      default: `${colorConstants.white}`,
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    tab: {
      fontSize: ".875rem",
      paddingRight: "1rem",
      paddingLeft: "1rem",
      display: "block",
      padding: ".5rem 1rem",
      color: `${colorConstants.black}`,
      textDecoration: "none",
      textTransform: "none",
    },
    h1: {
      fontFamily: "Montserrat",
      fontSize: "2.125rem",
      fontWeight: 700,
      letterSpacing: "0.5px",
    },
    h2: {
      fontFamily: "Montserrat",
      fontSize: "1.75rem",
      fontWeight: 700,
      letterSpacing: "0.3px",
    },
    h3: {
      fontFamily: "Montserrat",
      fontSize: "1.375rem",
      letterSpacing: "0.1px",
      fontWeight: 700,
    },
    h4: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "Montserrat",
      fontSize: "0.8125rem",
      fontWeight: 700,
    },
    h6: {
      fontFamily: "Montserrat",
      fontSize: "0.625rem",
      fontWeight: 700,
    },
  },
  overrides: {
    MuiTypography: {
      colorTextPrimary: {
        color: `${colorConstants.black}`,
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
        body: {
          fontSize: "1rem",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: `${colorConstants.medGray}`,
          color: `${colorConstants.medGray}`,
        },
        "&$focused $notchedOutline": {
          color: `${colorConstants.gray70}`,
          borderColor: `${colorConstants.darkBlue}`,
        },
        marginBottom: 0,
        minWidth: "270px",
      },
      input: {
        fontSize: "1rem",
        lineHeight: "1rem",
        color: `${colorConstants.gray70}`,
        backgroundClip: "padding-box",
        padding: ".738rem 1rem",
        backgroundColor: `${colorConstants.white}`,
        "&$disabled": {
          backgroundColor: `${colorConstants.lightGray}`,
        },
      },
      notchedOutline: {
        border: "1px solid",
        color: `${colorConstants.medGray}`,
        borderRadius: ".25rem",
        borderColor: `${colorConstants.medGray}`,
        "-webkit-transition":
          "border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out",
        transition:
          "border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: `${colorConstants.white}`,
        },
      },
    },
    MuiGrid: {
      container: {
        width: "100% !important",
        margin: "0 !important",
      },
    },
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        "&$disabled": {
          // your disabled styles here
        },
      },
      switchBase: {
        padding: 1,
        "&$checked": {
          transform: "translateX(16px)",
          color: `${colorConstants.white} !important`,
          "& + $track": {
            opacity: 1,
            border: "none",
            backgroundColor: `${colorConstants.primary}`,
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid ${colorConstants.gray40}`,
        backgroundColor: `${colorConstants.medGray}`,
        opacity: 1,
      },
      checked: {},
    },
    MuiCard: {
      root: {
        wordWrap: "break-word",
        backgroundColor: `${colorConstants.white}`,
        backgroundClip: "border-box",
        border: `1px solid ${colorConstants.lightGray}`,
        borderRadius: ".25rem",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
      },
    },
    MuiCardHeader: {
      root: {
        padding: "1rem",
        borderBottom: `1px solid ${colorConstants.lightGray}`,
        fontSize: ".75rem",
        alignItems: "center",
      },
    },
    MuiCardContent: {
      root: {
        padding: "1rem",
        color: `${colorConstants.black}`,
        marginBottom: "auto",
      },
    },
    MuiCardActions: {
      root: {
        padding: "1rem",
        borderBottom: `1px solid ${colorConstants.lightGray}`,
        fontSize: ".75rem",
        alignItems: "center",
      },
    },
    MuiTabs: {
      indicator: {
        height: "4px",
        backgroundColor: `${colorConstants.teal}`,
      },
      root: {
        "&.Mui-selected": {
          color: `${colorConstants.teal}`,
        },
      },
    },
    MuiButton: {
      root: { fontSize: "1rem", textTransform: "none" },
    },
    MuiDialogActions: {
      root: {
        padding: "1rem 1.5rem",
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
});
