import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/styles";

import Component from "../component";

const useStyles = makeStyles((theme) => ({
  buttonBar: {
    display: "flex",
  },
}));

export default function CustomForm(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `form-${componentNumber}`,
    classes: "",
    formItem: [
      {
        type: "span",
        data: {},
      },
    ],
    leftButton: {},
    centerButton: {},
    rightButton: {},
  };
  const config = { ...defaultProps, ...data };

  return (
    <form
      noValidate
      autoComplete="off"
      id={config.id}
      className={config.classes}
    >
      <Component
        type={config.formItem.type}
        data={config.formItem.data}
        callbackFn={callbackFn}
      />
      <div className={classes.buttonBar}>
        {config.leftButton.type && (
          <Component
            type={config.leftButton.type}
            data={config.leftButton.data}
            callbackFn={callbackFn}
          />
        )}
        {config.centerButton.type && (
          <Box component="div" mx={"auto"}>
            <Component
              type={config.centerButton.type}
              data={config.centerButton.data}
              callbackFn={callbackFn}
            />
          </Box>
        )}
        {config.rightButton.type && (
          <Component
            type={config.rightButton.type}
            data={config.rightButton.data}
            callbackFn={callbackFn}
          />
        )}
      </div>
    </form>
  );
}
