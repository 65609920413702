import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { Capitalize } from "../../utils/capitalize";
import Component from "../component";
import CustomIcon from "./customIcon";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modal: {
      zIndex: "9999 !important",
    },
    modalTitle: {
      fontSize: "1.5rem",
      width: "calc(100% - 35px)",
      display: "inline-block",
    },
    closeIconBtn: {
      position: "absolute",
      right: "24px",
      minWidth: 0,
      padding: 0,
    },
    closeIcon: {
      border: "1px solid",
      borderRadius: "50%",
    },
  })
);

export default function CustomModal(props) {
  const { data, componentNumber, callbackFn } = props;
  const defaultProps = {
    id: `modal-${componentNumber}`,
    classes: "",
    isOpen: false,
    hasClose: false,
  };
  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  const classes = useStyles();
  config.events = {
    primaryClickEvent: `onPrimaryClick${capitalId}`,
    secondaryClickEvent: `onSecondaryClick${capitalId}`,
    closeClickEvent: `onCloseClick${capitalId}`,
  };

  const renderContent = () => {
    if (typeof config.content === "string") {
      return config.content;
    } else if (typeof config.content === "object") {
      return (
        <Component
          type={config.content.type}
          data={config.content.data}
          callbackFn={callbackFn}
        />
      );
    }
  };

  function secondaryButtonClicked(event) {
    callbackFn(config.events.secondaryClickEvent, event);
  }

  function primaryButtonClicked(event) {
    callbackFn(config.events.primaryClickEvent, event);
  }

  function closeButtonClicked(event) {
    callbackFn(config.events.closeClickEvent, event);
  }

  return (
    <Dialog
      open={config.isOpen}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
      id={config.id}
    >
      <DialogTitle id="form-dialog-title">
        <span className={classes.modalTitle}>{config.title}</span>
        {config.hasClose && (
          <Button onClick={closeButtonClicked} className={classes.closeIconBtn}>
            <CustomIcon
              data={{
                name: "close",
                size: "md",
                classes: classes.closeIcon,
              }}
            />
          </Button>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{config.heading}</DialogContentText>
        {renderContent()}
      </DialogContent>
      <DialogActions>
        {config.secondaryText && (
          <Component
            type="button"
            data={{ text: config.secondaryText }}
            callbackFn={secondaryButtonClicked}
          />
        )}
        {config.primaryText && (
          <Component
            type="button"
            data={{ color: "primary", text: config.primaryText }}
            callbackFn={primaryButtonClicked}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
