import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import { useIdleTimer } from 'react-idle-timer'

import Component from "../component";
import Header from "../organisms/header";
import Footer from "../organisms/footer";

import { useActivity, useDemographics } from "../../hooks/custom-hooks";

export default function Page(props) {
  const { data, callbackFn, isSecure, logouturl } = props;
  const defaultProps = {
    id: `page`,
    classes: "",
    isSecure: false,
    layout: "default",
  };

  const { updateActiveState } = useActivity();
  const config = { ...defaultProps, ...data };
  let { columnWidths } = props;
  let allFullWidths = false;
  if (!columnWidths) {
    columnWidths = new Array(config.pageSections.length).fill(100);
    allFullWidths = true;
  }


  const {
    getDemographics,
    updateDemographics,
  } = useDemographics();

  const {
    userfirstname,
    userlastname,
    usermail,
    username,
    logoutURL,
    requestid
  } = getDemographics();

console.log(requestid,"requestid");

  const classes = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection:
        columnWidths.length > 1 && !allFullWidths ? "row" : "column",
      flexWrap: "wrap",
      flex: "1 0 auto",
      padding: config.layout === "banner" ? "0 0 20px" : "20px 0",
    },
    componentWrapper: {
      padding: columnWidths.length > 1 && !allFullWidths ? "4px" : 0,
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
      },
    },
  }))();

  //this is the timeout pop-up
  const timeoutModal = {
    id: "timeoutModal",
    title: "Timeout Warning",
    heading: "The system will timeout in 2 minutes. Do you wish to continue?",
    isOpen: false,
    content: "",
    hasClose: false,
    primaryText: "Yes",
    secondaryText: "No",
  };

  const [modalState, setModalState] = useState(timeoutModal);
  const setTimeoutModal = () => {
    clearTimeout(window.logoutTimer);
    window.logoutTimer = setTimeout(() => {
      window.location = "/logout";
      window.open(logouturl,
        "MILogin logout",
        "resizable,scrollbars,status").blur();
    }, 2 * 60 * 1000);
  };

  const modalCallbackFn = (eventType, data) => {
    setModalState({
      ...modalState,
      isOpen: false,
    });
    switch (eventType) {
      case "onSecondaryClickTimeoutModal":
        window.location = "/logout";
        window.open(logouturl,
          "MILogin logout",
          "resizable,scrollbars,status").blur();
        break;
      case "onPrimaryClickTimeoutModal":
        setTimeoutModal();
        break;
      default:
        console.log(eventType);
    }
  };

  function renderTimeoutModal() {
    return (
      <Component type="modal" data={modalState} callbackFn={modalCallbackFn} />
    );
  }

  const handleOnIdle = event => {
    setModalState((prevState) => {
      return { ...prevState,
      isOpen: true,
      isActive: false,
      }
    });
    setTimeoutModal();
  }

  const handleOnActive = (event) => {
    clearTimeout(window.logoutTimer);
    setModalState((prevState) => {
      return { ...prevState, isActive: true };
    });
  };

  const handleOnAction = event => {
    clearTimeout(window.logoutTimer);
    setModalState((prevState) => {
      return { ...prevState, isActive: true };
    });

  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 3 * 60 * 1000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    startManually : !isSecure
    // debounce: 500
  })

  return (
    <React.Fragment>
      <Header isSecure={config.isSecure} logouturl={logouturl}/>
      <div id={config.id} className={classes.root}>
        {Object.keys(config.pageSections).map((key, index) => {
          const component = config.pageSections[key];
          return (
            <div
              key={index}
              style={{ width: `${columnWidths[index]}%` }}
              className={classes.componentWrapper}
            >
              <Component
                type={component.type}
                data={component.data}
                callbackFn={callbackFn}
              />
            </div>
          );
        })}
      </div>
      <Footer isSecure={config.isSecure} />
      {isSecure && renderTimeoutModal()}
    </React.Fragment>
  );

}
