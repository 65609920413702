import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import CustomIcon from "./customIcon";

import { Capitalize } from "../../utils/capitalize";

const useStyles = makeStyles((theme) => ({
  startIcon: {
    marginRight: "10px",
  },
  endIcon: {
    marginLeft: "10px",
  },
}));

export default function CustomButton(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `button-${componentNumber}`,
    classes: "",
    text: "",
    variant: "contained",
    color: "default",
    href: "",
    startIcon: "",
    endIcon: "",
    isDisabled: false,
  };
  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  config.iconColor = config.variant === "outlined" ? "primary" : "white";
  config.events = {
    clickEvent: `onClick${capitalId}`,
  };

  const onClick = (event) => {
    callbackFn(config.events.clickEvent, event);
  };

  return (
    <Button
      id={config.id}
      className={config.classes}
      variant={config.variant}
      color={config.color}
      href={config.href}
      disableRipple
      endIcon={config.endIcon}
      onClick={onClick}
      disabled={config.isDisabled}
    >
      {config.startIcon && (
        <CustomIcon
          data={{
            name: config.startIcon,
            size: "md",
            classes: classes.startIcon,
            backgroundColor: "none",
            color: config.iconColor,
          }}
        />
      )}
      {config.text}
      {config.endIcon && (
        <CustomIcon
          data={{
            name: config.endIcon,
            classes: classes.endIcon,
            size: "md",
            backgroundColor: "none",
            color: config.iconColor,
          }}
        />
      )}
    </Button>
  );
}
