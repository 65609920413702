import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

import { Capitalize } from "../../utils/capitalize";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    marginBottom: "1rem",
  },
  icon: {
    alignItems: "center",
  },
  alertTitle: {
    margin: 0,
  },
}));

export default function CustomAlert(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `alert-${componentNumber}`,
    classes: "",
    type: "info",
    headline: "",
    subHeading: "",
    isDismissible: false,
    hasIcon: true,
    variant: "outlined",
    isOpen: false,
  };
  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  config.onClose = config.isDismissible ? onCloseHandler : null;
  config.events = {
    openEvent: `onOpen${capitalId}`,
    hideEvent: `onHide${capitalId}`,
    closeEvent: `onClose${capitalId}`,
  };

  /**
   * Function to handle close event of Alert
   */
  function onCloseHandler() {
    const eventData = {
      detail: {
        config,
      },
    };
    callbackFn(config.events.closeEvent, eventData);
  }

  return (
    config.isOpen && (
      <Alert
        className={`${classes.root} ${config.classes}`}
        classes={{ icon: classes.icon }}
        id={config.id}
        variant={config.variant}
        severity={config.type}
        onClose={config.onClose}
        {...(config.hasIcon ? { icon: false } : {})}
      >
        {config.headline && (
          <AlertTitle className={classes.alertTitle}>
            {config.headline}
          </AlertTitle>
        )}
        {config.subHeading && <span>{config.subHeading}</span>}
      </Alert>
    )
  );
}
