import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import TagManager from 'react-gtm-module';

import theme from "./components/theme";
import routes from "./configurations/componentconfigurations/routes.json";
import componentRegistry from "./componentRegistry";
import "./styles/styles.scss";


const tagManagerArgs = {
  gtmId: "GTM-WHWWV9X"
 }

TagManager.initialize(tagManagerArgs);


function App() {
  const routesUpdated = routes.map((route, index) => {
    return (
      <Route
        key={index}
        exact
        path={route.path}
        component={componentRegistry[route.component]}
      />
    );
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Switch>{routesUpdated}</Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
