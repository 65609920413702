import { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import sub from "date-fns/sub";
import add from "date-fns/add";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";

import { Capitalize } from "../../utils/capitalize";

const useStyles = makeStyles((theme) => ({
  label: { marginBottom: ".5rem", fontSize: ".875rem" },
  helpText: { marginLeft: 0 },
}));

export default function Datepicker(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `datepicker-${componentNumber}`,
    name: `datepicker-${componentNumber}`,
    label: "",
    classes: "",
    helpText: "",
    isDefaultWidth: true,
    isRequired: false,
    isLabelHidden: false,
    defaultValue: false,
    isDisabled: false,
    isDisablePast: false,
    isDisableFuture: false,
    minDate: undefined,
    maxDate: undefined,
  };
  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  config.helpTextId = `helper-text-${config.id}`;
  config.labelHiddenClass = config.isLabelHidden ? classes.labelHidden : "";
  config.events = {
    onChangeEvent: `onChange${capitalId}`,
  };

  const [selectedDate, setDate] = useState(
    config.defaultValue ? new Date(config.defaultValue) : null
  );

  function checkMinDays() {
    if (config.minDate && typeof config.minDate === "number") {
      config.minDate = sub(new Date(), {
        days: config.minDate,
      });
    }
  }

  function checkMaxDays() {
    if (config.maxDate && typeof config.maxDate === "number") {
      config.maxDate = add(new Date(), {
        days: config.maxDate,
      });
    }
  }

  function handleOnChange(date) {
    setDate(date);
    const eventData = {
      detail: {
        date,
      },
    };
    callbackFn(`onChange${capitalId}`, eventData);
  }

  checkMaxDays();
  checkMinDays();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl
        fullWidth={!config.isDefaultWidth}
        className={config.classes}
      >
        {config.label && (
          <label
            htmlFor={config.id}
            className={`${classes.label} ${config.labelHiddenClass}`}
          >
            {config.label}
          </label>
        )}
        <KeyboardDatePicker
          id={config.id}
          disableToolbar={true}
          variant="inline"
          value={selectedDate}
          placeholder="mm/dd/yyyy"
          onChange={(date) => handleOnChange(date)}
          minDate={config.minDate}
          format="MM/dd/yyyy"
          inputVariant="outlined"
          disabled={config.isDisabled}
          disableFuture={config.isDisableFuture}
          disablePast={config.isDisablePast}
          maxDate={config.maxDate}
          orientation="landscape"
        />
        {config.helpText && (
          <FormHelperText id={config.helpTextId} className={classes.helpText}>
            {config.helpText}
          </FormHelperText>
        )}
      </FormControl>
    </MuiPickersUtilsProvider>
  );
}
