import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headingLabel: {
    margin: 0,
    fontSize: ".7rem",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontWeight: 800,
  },
  subHeading: {
    margin: "0 0 1rem",
    fontWeight: 500,
  },
  underlinedHeading: {
    "&:after": {
      content: "' '",
      background: `${theme.palette.primary.main}`,
      width: "5rem",
      height: "5px",
      display: "block",
    },
  },
}));

export default function CustomTypography(props) {
  const { type, data, componentNumber } = props;
  const classes = useStyles();
  const theme = useTheme();
  const defaultProps = {
    id: `typography-${componentNumber}`,
    classes: "",
    headingLabel: "",
    content: "",
    subType: "inherit",
    subHeading: "",
    color: "initial",
    isUnderlined: false,
    backgroundColor: theme.palette.common.lightGray,
    desktopHeight: 0,
    mobileHeight: 0,
  };
  const config = { ...defaultProps, ...data };
  config.underlinedClass =
    config.isUnderlined && config.content ? classes.underlinedHeading : "";
  config.dividerMinHeight =
    config.desktopHeight > 0 && config.mobileHeight > 0 ? "1px" : 0;

  function renderHeading() {
    return (
      <div className={config.classes} id={config.id}>
        {config.headingLabel && (
          <p className={classes.headingLabel}>{config.headingLabel}</p>
        )}
        <Typography
          variant={config.subType}
          color={config.color}
          className={config.underlinedClass}
        >
          {config.content}
        </Typography>
        {config.subHeading && (
          <p className={classes.subHeading}>{config.subHeading}</p>
        )}
      </div>
    );
  }

  function renderParagraph() {
    if (typeof config.content === "string") {
      return (
        <Typography
          variant={config.subType}
          color={config.color}
          className={config.classes}
          id={config.id}
        >
          {config.content}
        </Typography>
      );
    } else if (typeof config.content === "object") {
      return (
        <Typography className={config.classes} id={config.id}>
          {config.content.map((contentItem, index) => {
            if (contentItem.tag === "link") {
              return (
                <Link
                  key={index}
                  href={contentItem.href}
                  color={contentItem.color}
                  className={contentItem.class}
                >
                  {contentItem.text}
                </Link>
              );
            } else if (contentItem.tag === "span") {
              return (
                <Typography
                  key={index}
                  variant="inherit"
                  color={contentItem.color}
                  className={contentItem.class}
                >
                  {contentItem.text}
                </Typography>
              );
            } else {
              return null;
            }
          })}
        </Typography>
      );
    }
  }

  function renderDivider() {
    const dividerStyles = makeStyles((theme) => ({
      divider: {
        backgroundColor: config.backgroundColor,
        minHeight: config.dividerMinHeight,
        height: `${config.desktopHeight}rem`,
        [theme.breakpoints.down("md")]: {
          height: `${config.mobileHeight}rem`,
        },
        margin: "0.5rem 0 1rem",
      },
    }))();
    return <div className={dividerStyles.divider} id={config.id}></div>;
  }

  return (
    <React.Fragment>
      {type === "heading" && renderHeading()}
      {type === "paragraph" && renderParagraph()}
      {type === "divider" && renderDivider()}
    </React.Fragment>
  );
}
