import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import FormHelperText from "@material-ui/core/FormHelperText";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Capitalize } from "../../utils/capitalize";

const useStyles = makeStyles((theme) => ({
  label: { fontSize: ".875rem" },
  helpText: { marginLeft: 0 },
}));

export default function CustomRadio(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `radio-${componentNumber}`,
    name: `radio-${componentNumber}`,
    label: "",
    classes: "",
    helpText: "",
    isSameRow: false,
    isDefaultWidth: true,
    isRequired: false,
    isLabelHidden: false,
    items: {
      key1: {
        displayName: "Display Name",
        isChecked: false,
        isDisabled: false,
      },
    },
    variant: "outlined",
  };
  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  config.helpTextId = `helper-text-${config.id}`;
  config.labelHiddenClass = config.isLabelHidden ? classes.labelHidden : "";
  config.events = {
    onChangeEvent: `onChange${capitalId}`,
  };

  function handleOnChange(event) {
    callbackFn(config.events.onChangeEvent, event);
  }

  function renderRadioGroup() {
    return (
      <RadioGroup
        value={config.value}
        name={config.name}
        onChange={handleOnChange}
        row={config.isSameRow}
      >
        {Object.keys(config.items).map((key, index) => {
          const item = config.items[key];
          return (
            <React.Fragment>
              {item.heading && <p>{item.heading}</p>}
              <FormControlLabel
              key={index}
              label={item.displayName}
              value={key}
              control={
                <Radio
                  disabled={item.isDisabled}
                  id={config.id + index}
                  disableRipple
                />
              }
            />
            </React.Fragment>
          );
        })}
      </RadioGroup>
    );
  }

  return (
    <FormControl
      variant={config.variant}
      fullWidth={!config.isDefaultWidth}
      className={config.classes}
    >
      {config.label && (
        <label
          htmlFor={config.id}
          className={`${classes.label} ${config.labelHiddenClass}`}
        >
          {config.label}
        </label>
      )}
      {renderRadioGroup()}
      {config.helpText && (
        <FormHelperText id={config.helpTextId} className={classes.helpText}>
          {config.helpText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
