import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { makeStyles, withStyles } from "@material-ui/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";

import { colorConstants } from "../constants";
import logo from "../../assets/logo.svg";
import headerConfig from "../../configurations/componentconfigurations/header.json";

/**
 *  Elevation scroll animation
 *  increase the number here if you want the elevation to appear later in the scroll
 **/ 
function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function mobileMenuTransition(props) {
  return <Fade direction="up" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  logo: {
    color: theme.palette.common.white,
    height: "40px",
    width: "40px",
    [theme.breakpoints.down("xs")]: {
      height: "32px",
      width: "32px",
    },
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: ".3em",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0em",
    },
  },
  appbar: {
    borderBottom: `1px solid ${theme.palette.common.lightGray}`,
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: `${colorConstants.lightGray}`,
  },
  logoContainer: {
    padding: "14px",
    background: theme.palette.common.brandGradient,
    borderRadius: 0,
    minWidth: 0,
    [theme.breakpoints.down("xs")]: {
      padding: "11px",
    },
  },
  toolBar: {
    minHeight: "10px",
  },
  departmentContainer: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: ".40625rem 1rem",
    [theme.breakpoints.down("xs")]: {
      margin: ".35625rem 1rem",
    },
  },
  departmentTitle: {
    color: theme.palette.common.black,
    fontWeight: 700,
    fontSize: "1.125rem",
  },
  subDepartmentTitle: {
    color: theme.palette.common.gray70,
    fontSize: ".8125rem",
    fontWeight: 400,
  },
  tabContainer: {
    marginLeft: "auto",
    marginRight: "1rem",
    alignItems: "center",
  },
  tab: {
    ...theme.typography.tab,
    minHeight: 0,
    minWidth: 0,
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
  menuIconContainer: {
    marginLeft: "auto",
  },
  closeMenuIcon: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "3px",
    borderRadius: "15px",
  },
  menuListItem: {
    ...theme.typography.tab,
    padding: "0px",
    textAlign: "center",
  },
  indicator: {
    display: "none",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
    ></MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: "0px",
    margin: "auto",
    flex: "none",
    overflow: "hidden",
  },
}))(MuiDialogContent);

export default function Header(props) {
  const { isSecure, logouturl } = props;
  const classes = useStyles();
  const theme = useTheme();
  /* state used to manage which tab is active */
  const [activeTab, setActiveTab] = useState(0);

  /* state used to manage page change on logout */
  let history = useHistory();

  /**
   * function to manage which tab stays active
   * @param {Event} e
   * @param {*} newValue
   */
  const handleTabClick = (e, newValue) => {
    setActiveTab(newValue);
  };

  /* state that controls how the menu item is opened or closed */
  const [menuDialogOpen, setMenuDialogOpen] = useState(false);

  /**
   * function to manage the closing of the dialog component
   */
  const handleCloseMenuDialog = () => {
    setMenuDialogOpen(false);
  };

  /**
   * function to manage the opening of the dialog component
   */
  const handleOpenMenuDialog = () => {
    setMenuDialogOpen(true);
  };

  const openFaqTab = () => {
    window.open("https://www.michigan.gov/mdhhs/adult-child-serv/childrenfamilies/immunization/michigan-immunization-portal/faq/michigan-immunization-portal-faq",
      "MILogin FAQ",
      "resizable,scrollbars,status");
  }

  /**
   * function to open logout window 
   */
  const openLogoutTab = () => {
    window.location = "/logout";
    window.open(logouturl,
      "MILogin logout",
      "resizable,scrollbars,status").blur();
  };

  /* code to check the form factor to handle hamburger menu */
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  /* fragment for tabs -- this will get rendered on a large display */
  const menuForLargerDisplays = (
    <Tabs
      classes={{ indicator: classes.indicator }}
      className={classes.tabContainer}
      onChange={handleTabClick}
      value={activeTab}
    >
      {headerConfig.headerLinks?.map((link, index) => {
        return (
          <Tab
            className={classes.tab}
            component={Link}
            onClick={openFaqTab}
            label={link.label}
            tabindex="3"
            key={index}
            disableRipple
          ></Tab>
        );
      })}
      {isSecure && (
        <Tab
          className={classes.tab}
          component={Link}
          onClick={openLogoutTab}
          to={"/logout"}
          label={"Logout"}
          tabindex="4"
          key={headerConfig.headerLinks.length}
          disableRipple
        ></Tab>
      )}
    </Tabs>
  );

  /* close icon that needs to be displayed on the menu */
  const closeIcon = (
    <IconButton
      className={classes.menuIconContainer}
      disableRipple
      onClick={handleCloseMenuDialog}
    >
      <CloseIcon className={classes.closeMenuIcon} />
    </IconButton>
  );

  /* open icon needs to be displayed on the menu */
  const openIcon = (
    <IconButton
      className={classes.menuIconContainer}
      disableRipple
      onClick={handleOpenMenuDialog}
    >
      <MenuIcon className={classes.menuIcon} />
    </IconButton>
  );

  /* fragment for drawer -- this will get rendered on a small display */
  const menuForSmallerDisplays = (
    <React.Fragment>
      {menuDialogOpen ? closeIcon : openIcon}
      <Dialog
        onClose={handleCloseMenuDialog}
        aria-labelledby="customized-dialog-title"
        open={menuDialogOpen}
        fullScreen={mobileView}
        className={classes.menuDialog}
        transitionDuration={600}
        TransitionComponent={mobileMenuTransition}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseMenuDialog}
        ></DialogTitle>
        <DialogContent dividers>
          <List>
            {headerConfig.headerLinks?.map((link, index) => {
              return (
                <Slide
                  direction="up"
                  key={index}
                  in={menuDialogOpen}
                  timeout={500}
                  mountOnEnter
                  unmountOnExit
                >
                  <ListItem
                    onClick={handleCloseMenuDialog}
                    disableGutters
                    onClick={openFaqTab}
                    component={Link}
                  >
                    <ListItemText
                      className={classes.menuListItem}
                      disableTypography
                      primary={link.label}
                    />
                  </ListItem>
                </Slide>
              );
            })}
            {isSecure && (
              <Slide
                direction="up"
                key={headerConfig.headerLinks.length}
                in={menuDialogOpen}
                timeout={500}
                mountOnEnter
                unmountOnExit
              >
                <ListItem
                  onClick={handleCloseMenuDialog && openLogoutTab}
                  disableGutters
                  //to={"/"}
                  to={"/logout"}
                  label={"Logout"}
                  component={Link}
                >
                  <ListItemText
                    className={classes.menuListItem}
                    disableTypography
                    primary={"Logout"}
                  />
                </ListItem>
              </Slide>
            )}
          </List>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed" className={classes.appbar}>
          <Toolbar disableGutters className={classes.toolBar}>
            <Button
              disableRipple
              className={classes.logoContainer}
              to="/"
              component={Link}
              tabIndex="2" 
            >
              <img src={logo} className={classes.logo} alt="Department Logo" />
            </Button>
            <div className={classes.departmentContainer}>
              <Typography className={classes.departmentTitle} variant="body2">
                {headerConfig.deptName}
              </Typography>
              <Typography
                className={classes.subDepartmentTitle}
                variant="body2"
              >
                {headerConfig.subDeptName}
              </Typography>
            </div>
            {mobileView ? menuForSmallerDisplays : menuForLargerDisplays}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin}></div>
    </React.Fragment>
  );
}
