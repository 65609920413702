import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/styles";

import Component from "../component";
import { Capitalize } from "../../utils/capitalize";

const useStyles = makeStyles((theme) => ({
  small: {
    width: "20rem",
  },
  large: {
    width: "30rem",
  },
}));

export default function Panel(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `panel-${componentNumber}`,
    classes: "",
    isOpen: false,
    overlay: true,
    size: "sm",
    position: "left",
    panelItem: {
      type: "span",
      data: {},
    },
  };
  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  config.panelSize = data.size === "lg" ? classes.large : classes.small;
  config.events = {
    closeEvent: `onClose${capitalId}`,
  };

  /**
   * Function to handle close event of Panel
   */
  function onCloseHandler(event) {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    const eventData = {
      detail: {
        config,
      },
    };
    callbackFn(config.events.closeEvent, eventData);
  }

  return (
    <Drawer
      BackdropProps={{ invisible: !config.overlay }}
      classes={{
        paper: config.panelSize,
      }}
      className={config.classes}
      anchor={config.position}
      onClose={onCloseHandler}
      open={config.isOpen}
    >
      <Component type={config.panelItem.type} data={config.panelItem.data} />
    </Drawer>
  );
}
