import { useEffect, useState, useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Capitalize } from "../../utils/capitalize";

const useStyles = makeStyles((theme) => ({
  label: { fontSize: ".875rem" },
  helpText: { marginLeft: 0 },
  labelHidden: { visibility: "hidden" },
}));

export default function CustomCheckbox(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `checkbox-${componentNumber}`,
    label: "",
    classes: "",
    helpText: "",
    isSameRow: false,
    isDefaultWidth: true,
    isRequired: false,
    isLabelHidden: false,
    items: {
      displayName: "Display Name",
      key: "UniqueKey",
      isChecked: false,
      isDisabled: false,
    },
    variant: "outlined",
  };
  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  config.helpTextId = `helper-text-${config.id}`;
  config.labelHiddenClass = config.isLabelHidden ? classes.labelHidden : "";
  config.events = {
    onChangeEvent: `onChange${capitalId}`,
  };

  let defaultState = {};
  // config.items.forEach(
  //   (item) => (defaultState[item.key] = item.isChecked || false)
  // );
  let [state, setState] = useState(defaultState);
  const loaded = useRef(false);

  // Using useEffect as state is not updated immediately for a checkbox in handleChange
  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
    } else {
      callbackFn(config.events.onChangeEvent, state);
    }
  }, [state]);

  function handleOnChange(event) {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  function renderCheckbox() {
    return (
      <FormControlLabel
        label={config.items.displayName}
        control={
          <Checkbox
            checked={config.items.isChecked}
            name={config.items.key}
            disabled={config.items.isDisabled}
            disableRipple
            onChange={handleOnChange}
          />
        }
      />
    );
  }

  // function renderCheckboxGroup() {
  //   return (
  //     <FormGroup row={config.isSameRow}>
  //       {config.items.map((item, index) => {
  //         return (
  //           <FormControlLabel
  //             key={index}
  //             label={item.displayName}
  //             control={
  //               <Checkbox
  //                 checked={state[item.key]}
  //                 disabled={item.isDisabled}
  //                 name={item.key}
  //                 onChange={handleOnChange}
  //                 disableRipple
  //               />
  //             }
  //           />
  //         );
  //       })}
  //     </FormGroup>
  //   );
  // }

  return (
    <FormControl
      variant={config.variant}
      fullWidth={!config.isDefaultWidth}
      className={config.classes}
      id={config.id}
    >
      {config.label && (
        <label
          htmlFor={config.id}
          className={`${classes.label} ${config.labelHiddenClass}`}
        >
          {config.label}
        </label>
      )}

      {config.items && renderCheckbox()}
      {/* {config.items.length > 1 && renderCheckboxGroup()} */}

      {config.helpText && (
        <FormHelperText id={config.helpTextId} className={classes.helpText}>
          {config.helpText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
