// Refer https://material.io/resources/icons/?style=baseline for the list of icons

import { makeStyles } from "@material-ui/styles";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    width: "auto",
    borderRadius: "5px",
    overflow: "hidden",
    maxWidth: "60px",
  },
  smIcon: {
    fontSize: "1rem",
  },
  mdIcon: {
    fontSize: "1.5rem",
  },
  lgIcon: { fontSize: "2rem" },
  xlIcon: { fontSize: "2.5rem" },
  xxlIcon: { fontSize: "3rem" },
}));

export default function CustomIcon(props) {
  const { data, componentNumber } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `icon-${componentNumber}`,
    classes: "",
    color: "black",
    backgroundColor: "white",
    size: "md",
    name: "",
  };
  const config = { ...defaultProps, ...data };
  switch (config.size) {
    case "sm":
      config.sizeClass = classes.smIcon;
      break;
    case "md":
      config.sizeClass = classes.mdIcon;
      break;
    case "lg":
      config.sizeClass = classes.lgIcon;
      break;
    case "xl":
      config.sizeClass = classes.xlIcon;
      break;
    case "xxl":
      config.sizeClass = classes.xxlIcon;
      break;
    default:
      config.sizeClass = classes.md;
  }

  return (
    <Icon
      className={`${classes.root} ${config.classes} ${config.sizeClass} ${
        config.variant === "outlined" ? "material-icons-outlined" : ""
      }`}
      id={config.id}
      style={{ backgroundColor: config.backgroundColor, color: config.color }}
    >
      {config.name}
    </Icon>
  );
}
