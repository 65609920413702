import { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Component from "../component";

const useStyles = makeStyles((theme) => ({
  sameHeight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  centerAlignLastItems: {
    justifyContent: "space-evenly",
  },
  break: {
    flexBasis: "100%",
    height: "0",
  },
}));

export default function CustomGrid(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `grid-${componentNumber}`,
    classes: "",
    spacing: 1,
    direction: "row",
    justify: "flex-start",
    alignItems: "stretch",
    backgroundColor: "",
    isCenterAlignLastItems: false,
    isContainer: false,
    gridItems: [
      {
        type: "break",
        isSameHeight: true,
        xs: 12,
        isHidden: false,
        id: "",
        classes: "",
      },
    ],
  };
  const config = { ...defaultProps, ...data };
  config.centerAlignLastItemsClass = config.isCenterAlignLastItems
    ? classes.centerAlignLastItems
    : "";

  function renderGrid() {
    return (
      <Grid
        container
        id={config.id}
        spacing={config.spacing}
        direction={config.direction}
        justifyContent={config.justify}
        alignItems={config.alignItems}
        className={`${config.centerAlignLastItemsClass}`}
      >
        {renderGridItems()}
      </Grid>
    );
  }

  function renderGridItems() {
    return Object.keys(config.gridItems).map((key, index) => {
      const gridItem = config.gridItems[key];
      gridItem.sameHeightClass = gridItem.isSameHeight
        ? classes.sameHeight
        : "";
      gridItem.id = gridItem.id ? gridItem.id : `${config.id}-item-${index}`;
      if (gridItem.data) {
        gridItem.data.id = key;
      }

      if (gridItem.type === "break") {
        return <Grid item className={classes.break} key={index}></Grid>;
      }
      return (
        <Grid
          item
          id={gridItem.id}
          className={`${gridItem.sameHeightClass} ${
            gridItem.classes ? gridItem.classes : ""
          }`}
          key={index}
          xs={gridItem.xs}
          sm={gridItem.sm}
          md={gridItem.md}
          lg={gridItem.lg}
          xl={gridItem.xl}
          hidden={gridItem.isHidden}
        >
          {gridItem.type !== "empty" && (
            <Component
              type={gridItem.type}
              data={gridItem.data}
              callbackFn={callbackFn}
            />
          )}
        </Grid>
      );
    });
  }

  return (
    <Typography
      component="div"
      style={{ backgroundColor: config.backgroundColor, width: "100%" }}
      className={config.classes}
    >
      {config.isContainer && (
        <Container>
          {config.backgroundColor && (
            <Typography
              component="div"
              style={{ backgroundColor: config.backgroundColor, width: "100%" }}
            >
              {renderGrid()}
            </Typography>
          )}
          {!config.backgroundColor && renderGrid()}
        </Container>
      )}
      {!config.isContainer && (
        <Fragment>
          {config.backgroundColor && (
            <Typography
              component="div"
              style={{ backgroundColor: config.backgroundColor, width: "100%" }}
            >
              {renderGrid()}
            </Typography>
          )}
          {!config.backgroundColor && renderGrid()}
        </Fragment>
      )}
    </Typography>
  );
}
