import { useState } from "react";
import moment from "moment";
import parser from "parse-address";

/**
 * This is the react custom hook that is used to check if the session is active or not.
 * @param {true} initialValue 
 * @returns {boolean}
 */
export const useActivity = (initialValue = true) => {
  const [isActive, setActive] = useState();
  return {
    getIsActive : () => {
      return isActive;
    },
    updateActiveState : (value) => {
      setActive(value);
    },
  }
}

/**
 * This is a react custom hook that is used to check if the submission button can be enabled or not.
 * @param {false} initialValue 
 * @returns {boolean}
 */
export const useEnableSubmission = (initialValue = false) => {
    const [consent, setConsent] = useState(initialValue);
    const [isRecaptcha, setIsRecaptcha] = useState(initialValue);
    return {
        getConsent : () => {
            return consent;
        },
        updateConsent : (value) => {
            setConsent(value);
        },
        getIsRecaptcha : () => {
            return isRecaptcha;
        },
        updateIsRecaptcha : (value) => {
            setIsRecaptcha(value);
        },
        resetIsRecaptcha : () => {
          setIsRecaptcha(initialValue)
        },
        resetConsent : () => {
          setConsent(initialValue)
        }
    }
}

/**
 * This is a react custom hook that is used to maintain state of SSN and PHN validation.
 * @param {false} initialValue 
 * @returns {boolean}
 */
export const useValidation = (initialValue = false) => {
  const [notValidSSN, setNotValidSSN] = useState(initialValue);
  const [notValidPHN, setNotValidPHN] = useState(initialValue);
  return {
    getSSNStatus: () => {
      return notValidSSN;
    },
    updateSSNStatus : (value) => {
      setNotValidSSN(value);
    },
    resetSSNStatus : () => {
      setNotValidSSN(initialValue)
    },
    getPHNStatus: () => {
      return notValidPHN;
    },
    updatePHNStatus : (value) => {
      setNotValidPHN(value);
    },
    resetPHNStatus : () => {
      setNotValidPHN(initialValue)
    },
  } 
}

/**
 * This is a react custom hook that is used to maintain the users session info.
 * @param {empty array} initialValue 
 * @returns {array of content of logged in user}
 */
export const useDemographics = (initialValue = []) => {
  const [demographics, setDemographics] = useState(initialValue);
  return {
    getDemographics: () => {
      return demographics;
    },
    updateDemographics: (value) => {
      const {
        userfirstname,
        userlastname,
        usermail,
        username,
        logouturl,
        requestid,
      } = value;
      setDemographics(value);
    },
    clearDemographics: () => {
      setDemographics(initialValue);
    }
  };
}

/**
 * This is a react custom hook that is used to maintain state of document region.
 * @param {empty string} initialValue 
 * @returns {String}
 */
export const useDocRegion = (initialValue = "") => {
  const [docRegion, setDocRegion] = useState(initialValue);
  return {
    getDocRegion: () => {
      return docRegion;
    },
    updateDocRegion: (value) => {
      if (value) {
        setDocRegion(value);
      } else {
        setDocRegion(initialValue);
      }
    },
    resetDocRegion: () => {
      setDocRegion(initialValue);
    },
  };
};

/**
 * This is a react custom hook that is used to maintain state of First Name.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useFstName = (initialValue = "") => {
  const [fstName, setFstName] = useState(initialValue);
  return {
    getFstName: () => {
      return fstName;
    },
    updateFstName: (value) => {
      if (value) {
        setFstName(value);
      } else {
        setFstName(initialValue);
      }
    },
    resetFstName: () => {
      setFstName(initialValue);
    },
  };
};

/**
 * This is a react custom hook that is used to maintain state of Middle Name.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useMdlName = (initialValue = "") => {
  const [mdlName, setMdlName] = useState(initialValue);
  return {
    getMdlName: () => {
      return mdlName;
    },
    updateMdlName: (value) => {
      if (value) {
        setMdlName(value);
      } else {
        setMdlName(initialValue);
      }
    },
    resetMdlName: () => {
      setMdlName(initialValue);
    },
  };
};

/**
 * This is a react custom hook that is used to maintain state of Last Name.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useLstName = (initialValue = "") => {
  const [lstName, setLstName] = useState(initialValue);
  return {
    getLstName: () => {
      return lstName;
    },
    updateLstName: (value) => {
      if (value) {
        setLstName(value);
      } else {
        setLstName(initialValue);
      }
    },
    resetLstName: () => {
      setLstName(initialValue);
    },
  };
};

/**
 * This is a react custom hook that is used to maintain state of Suffix.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useSuffix = (initialValue = "") => {
  const [suffix, setSuffix] = useState(initialValue);
  return {
    getSuffix: () => {
      return suffix;
    },
    updateSuffix: (value) => {
      if (value) {
        setSuffix(value);
      } else {
        setSuffix(initialValue);
      }
    },
    resetSuffix: () => {
      setSuffix(initialValue);
    },
  };
};

/**
 * This is a react custom hook that is used to maintain state of Gender.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useGender = (initialValue = "") => {
  const [gender, setGender] = useState(initialValue);
  return {
    getGender: () => {
      return gender;
    },
    updateGender: (value) => {
      if (value) {
        setGender(value);
      } else {
        setGender(initialValue);
      }
    },
    resetGender: () => {
      setGender(initialValue);
    },
  };
};

/**
 * This is a react custom hook that is used to maintain state of Date of Birth with format mm/dd/yyyy.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useDobDate = (initialValue = "") => {
  const [dobDate, setDobDate] = useState(initialValue);
  return {
    getDobDate: () => {
      return dobDate;
    },
    updateDobDate: (value) => {
      if (value) {
        setDobDate(moment(value).format("MM/DD/YYYY"));
      } else {
        setDobDate(initialValue);
      }
    },
    ageReqSatisfied: (value) => {
      if (value &&
        moment(value).format("MM/DD/YYYY") >=
          moment(new Date()).format("MM/DD/YYYY")
      ) {
        return true;
      }
    },
    resetDobDate: () => {
      setDobDate(initialValue);
    },
  };
};

/**
 * This is a react custom hook that is used to maintain state of Date of Birth with format yyyymmdd.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useDobDateMPI = (initialValue = "") => {
  const [dobDateMPI, setDobDateMPI] = useState(initialValue);
  return {
    getDobDateMPI: () => {
      return dobDateMPI;
    },
    updateDobDateMPI: (value) => {
      if (value) {
        setDobDateMPI(moment(value).format("YYYYMMDD"));
      } else {
        setDobDateMPI(initialValue);
      }
    },
    resetDobDateMPI: () => {
      setDobDateMPI(initialValue);
    },
  };
};

/**
 * This is a react custom hook that is used to maintain state of document number.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useDocNumber = (initialValue = "") => {
  const [docNumber, setDocNumber] = useState(initialValue);
  return {
    getDocNumber: () => {
      return docNumber;
    },
    updateDocNumber: (value) => {
      if (value) {
        setDocNumber(value);
      } else {
        setDocNumber(initialValue);
      }
    },
    resetDocNumber: () => {
      setDocNumber(initialValue);
    },
  };
};

/**
 * This is a react custom hook that is used to maintain state of document type.
 * passport, drivers license or unknown
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useDocType = (initialValue = "") => {
    const [docType, setDocType] = useState(initialValue);
    return {
      getDocType: () => {
        return docType;
      },
      updateDocType: (value) => {
        if (value) {
          setDocType(value);
        } else {
          setDocType(initialValue);
        }
      },
      resetDocType: () => {
        setDocType(initialValue);
      },
    };
  };


/**
 * This is a react custom hook that is used to maintain state of document expiration date with format mm/dd/yyyy.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useDocExpDate = (initialValue = "") => {
  const [docExpDate, setDocExpDate] = useState(initialValue);
  return {
    getDocExpDate: () => {
      return docExpDate;
    },
    updateDocExpDate: (value) => {
      if (value) {
        setDocExpDate(moment(value).format("MM/DD/YYYY"));
      } else {
        setDocExpDate(initialValue);
      }
    },
    resetDocExpDate: () => {
      setDocExpDate(initialValue);
    },
  };
};

/**
 * This is a react custom hook that is used to maintain state of document expiration date with format yyyy/mm/dd.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useDocExpDateMPI = (initialValue = "") => {
    const [docExpDateMPI, setDocExpDateMPI] = useState(initialValue);
    return {
      getDocExpDateMPI: () => {
        return docExpDateMPI;
      },
      updateDocExpDateMPI: (value) => {
        if (value) {
          setDocExpDateMPI(moment(value).format("YYYYMMDD"));
        } else {
          setDocExpDateMPI(initialValue);
        }
      },
      resetDocExpDateMPI: () => {
        setDocExpDateMPI(initialValue);
      },
    };
  };

/**
 * This is a react custom hook that is used to maintain state of SSN.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useSsnNum = (initialValue = "") => {
  const [ssnNum, setSsnNum] = useState(initialValue);
  return {
    getSsnNum: () => {
      return ssnNum;
    },
    updateSsnNum: (value) => {
      if (value) {
        setSsnNum(value);
      } else {
        setSsnNum(initialValue);
      }
    },
    resetSsnNum: () => {
      setSsnNum(initialValue);
    },
  };
};

/**
 * This is a react custom hook that is used to maintain state of Phone number.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const usePhnNum = (initialValue = "") => {
  const [phnNum, setPhnNum] = useState(initialValue);
  return {
    getPhnNum: () => {
      return phnNum;
    },
    updatePhnNum: (value) => {
      if (value) {
        setPhnNum(value);
      } else {
        setPhnNum(initialValue);
      }
    },
    resetPhnNum: () => {
      setPhnNum(initialValue);
    },
  };
};

/**
 * This is a react custom hook that is used to maintain state of Address, parsed address and MPI format address.
 * @param {empty String} initialValue 
 * @returns {String}
 */
export const useAddress = (initialValue = "") => {
  const [address, setAddress] = useState(initialValue);
  const [country, setCountry] = useState(initialValue);
  const [validatedAddress, setValidatedAddress] = useState(initialValue);
  const [mpiAddressOne, setMpiAddressOne] = useState(initialValue);
  const [mpiCity, setMpiCity] = useState(initialValue);
  const [mpiState, setMpiState] = useState(initialValue);
  const [mpiZip, setMpiZip] = useState(initialValue);
  const [parsedStreet, setParsedStreet] = useState(initialValue);
  const [parsedCity, setParsedCity] = useState(initialValue);
  const [parsedState, setParsedState] = useState(initialValue);
  const [parsedZip, setParsedZip] = useState(initialValue);
  const [parsedCountry, setParsedCountry] = useState(initialValue);
  return {
    parseFullAddress: (value) => {
      const parsed = parser.parseLocation(value);
      let addOne = "";
      if (parsed && parsed.number) {
        addOne = addOne + parsed.number;
      } else {
        addOne = addOne + "";
      }
      if (parsed && parsed.prefix) {
        addOne = addOne + " " + parsed.prefix;
      } else {
        addOne = addOne + "";
      }
      if (parsed && parsed.street) {
        addOne = addOne + " " + parsed.street;
      } else {
        addOne = addOne + "";
      }
      if (parsed && parsed.type) {
        addOne = addOne + " " + parsed.type;
      } else {
        addOne = addOne + "";
      }
      if (addOne) {
        setParsedStreet(addOne);
      } else {
        setParsedStreet(initialValue);
      }
      if (parsed && parsed.city) {
        setParsedCity(parsed.city);
      } else {
        setParsedCity(initialValue);
      }
      if (parsed && parsed.state) {
        setParsedState(parsed.state);
      } else {
        setParsedState(initialValue);
      }
      if (parsed && parsed.zip){
        if(parsed.plus4){
          setParsedZip(`${parsed.zip}-${parsed.plus4}`);
        } else {
          setParsedZip(parsed.zip);
        }
      } else {
        setParsedZip(initialValue);
      }
    },
    getAddress: () => {
      return address;
    },
    updateAddress: (value) => {
      if (value) {
        setAddress(value);
      } else {
        setAddress(initialValue);
      }
    },
    resetAddress: () => {
      setAddress(initialValue);
    },
    getValidatedAddress: () => {
      return validatedAddress;
    },
    updateValidateAddress: (value) => {
      if (value) {
        setValidatedAddress(value);
      } else {
        setValidatedAddress(initialValue);
      }
    },
    resetValidatedAddress: () => {
      setValidatedAddress(initialValue);
    },
    getMpiAddrOne: () => {
      if (mpiAddressOne) {
        return mpiAddressOne;
      }
    },
    updateMpiAddressOne: (value) => {
      if (value) {
        setMpiAddressOne(value);
      } else {
        setMpiAddressOne(initialValue);
      }
    },
    resetMpiAddressOne: () => {
      setMpiAddressOne(initialValue);
    },

    getMpiCity: () => {
      if (mpiCity) {
        return mpiCity;
      }
    },
    updateMpiCity: (value) => {
      if (value) {
        setMpiCity(value);
      } else {
        setMpiCity(initialValue);
      }
    },
    resetMpiCity: () => {
      setMpiCity(initialValue);
    },
    getMpiState: () => {
      if (mpiState) {
        return mpiState;
      }
    },
    updateMpiState: (value) => {
      if (value) {
        setMpiState(value);
      } else {
        setMpiState(initialValue);
      }
    },
    resetMpiState: () => {
      setMpiState(initialValue);
    },
    getMpiZip: () => {
      if (mpiZip) {
        return mpiZip;
      }
    },
    updateMpiZip: (value) => {
      if (value) {
        setMpiZip(value);
      } else {
        setMpiZip(initialValue);
      }
    },
    resetMpiZip: () => {
      setMpiZip(initialValue);
    },
    getCountry: () => {
      if (country) {
        return country;
      }
    },
    updateCountry: (value) => {
      if (value) {
        setCountry(value);
      } else {
        setCountry(initialValue);
      }
    },
    resetCountry: () => {
      setCountry(initialValue);
    },
    getParsedStreet: () => {
      if (parsedStreet) {
        return parsedStreet;
      }
    },
    updateParsedStreet: (value) => {
      if (value) {
        setParsedStreet(value);
      } else {
        setParsedStreet(initialValue);
      }
    },
    resetParsedStreet: () => {
      setParsedStreet(initialValue);
    },
    getParsedCity: () => {
      if (parsedCity) {
        return parsedCity;
      }
    },
    updateParsedCity: (value) => {
      if (value) {
        setParsedCity(value);
      } else {
        setParsedCity(initialValue);
      }
    },
    resetParsedCity: () => {
      setParsedCity(initialValue);
    },
    getParsedState: () => {
      if (parsedState) {
        return parsedState;
      }
    },
    updateParsedState: (value) => {
      if (value) {
        setParsedState(value);
      } else {
        setParsedState(initialValue);
      }
    },
    resetParsedState: () => {
      setParsedState(initialValue);
    },
    getParsedZip: () => {
      if (parsedZip) {
        return parsedZip;
      }
    },
    updateParsedZip: (value) => {
      if (value) {
        setParsedZip(value);
      } else {
        setParsedZip(initialValue);
      }
    },
    resetParsedZip: () => {
      setParsedZip(initialValue);
    },
    getParsedCountry: () => {
      if (parsedCountry) {
        return parsedCountry;
      }
    },
    updateParsedCountry: (value) => {
      if (value) {
        setParsedCountry(value);
      } else {
        setParsedCountry(initialValue);
      }
    },
    resetParsedCountry: () => {
      setParsedCountry(initialValue);
    }
  };
};