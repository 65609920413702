import { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";

import Component from "../component";
import componentRegistry from "../../componentRegistry";

const useStyles = makeStyles((theme) => ({
  appbar: {
    width: "auto",
  },
  disableIndicator: {
    display: "none",
  },
  tab: {
    minWidth: "100px",
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
  vertical: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  tabPanel: {
    padding: "1rem 2rem",
    width: "100%",
  },
}));

export default function CustomTabs(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const defaultProps = {
    id: `tabs-${componentNumber}`,
    classes: "",
    defaultValue: "0",
    isCentered: false,
    backgroundColor: "",
    orientation: "horizontal",
    isDisableIndicator: false,
    tabItems: [
      {
        name: "",
        isDisabled: false,
        tabPanelItem: { type: "span", data: {} },
      },
      {
        name: "",
        renderComponent: "",
      },
    ],
    barPosition: "relative",
    scrollButtons: "auto",
    variant: "fullWidth",
  };
  const config = { ...defaultProps, ...data };
  config.orientationClass =
    config.orientation === "vertical" ? classes.vertical : "";
  config.disableIndicatorClass = config.isDisableIndicator
    ? classes.disableIndicator
    : "";

  config.defaultValue = `${config.defaultValue}`;
  const [value, setValue] = useState(config.defaultValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    callbackFn(event, newValue);
  };

  return (
    <div className={`${config.orientationClass} ${config.classes}`}>
      <TabContext value={value}>
        <AppBar
          position={config.barPosition}
          color={config.backgroundColor}
          className={classes.appbar}
        >
          <Tabs
            id={config.id}
            value={value}
            onChange={handleChange}
            orientation={`${matches ? "horizontal" : config.orientation}`}
            classes={{
              root: classes.tabs,
              indicator: config.disableIndicatorClass,
            }}
            centered={config.isCentered}
            scrollButtons={config.scrollButtons}
            variant={config.variant}
          >
            {config.tabItems.map((tabItem, index) => {
              return (
                <Tab
                  disableRipple
                  key={index}
                  value={`${index}`}
                  label={tabItem.name}
                  disabled={tabItem.isDisabled}
                  className={classes.tab}
                />
              );
            })}
          </Tabs>
        </AppBar>
        {config.tabItems.map((tabItem, index) => {
          return (
            <TabPanel
              key={index}
              value={`${index}`}
              className={classes.tabPanel}
            >
              {tabItem.tabPanelItem && (
                <Component
                  type={tabItem.tabPanelItem.type}
                  data={tabItem.tabPanelItem.data}
                  callbackFn={callbackFn}
                />
              )}
              {tabItem.renderComponent && (
                <Box component={componentRegistry[tabItem.renderComponent]} />
              )}
            </TabPanel>
          );
        })}
      </TabContext>
    </div>
  );
}
