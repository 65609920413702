import { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/styles";

import { Capitalize } from "../../utils/capitalize";

const useStyles = makeStyles((theme) => ({
  label: { marginBottom: ".5rem", fontSize: ".875rem" },
  helpText: { marginLeft: 0 },
}));

export default function CustomSwitch(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `switch-${componentNumber}`,
    name: `switch-${componentNumber}`,
    label: "",
    classes: "",
    helpText: "",
    isDefaultWidth: true,
    isRequired: false,
    isLabelHidden: false,
    defaultValue: false,
    isDisabled: false,
  };

  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  config.helpTextId = `helper-text-${config.id}`;
  config.labelHiddenClass = config.isLabelHidden ? classes.labelHidden : "";
  config.events = {
    onChangeEvent: `onChange${capitalId}`,
  };

  const [state, setState] = useState(config.defaultValue);

  function handleOnChange(event) {
    setState(event.target.checked);
    const eventData = {
      detail: {
        config,
      },
    };
    callbackFn(config.events.onChangeEvent, eventData);
  }

  return (
    <FormControl className={config.classes}>
      <FormControlLabel
        control={
          <Switch
            id={config.id}
            checked={state}
            onChange={handleOnChange}
            name={config.name}
            disableRipple
            disabled={config.isDisabled}
          />
        }
        label={config.label}
        classes={{
          label: `${classes.label} ${config.labelHiddenClass}`,
        }}
      />
      {config.helpText && (
        <FormHelperText id={config.helpTextId} className={classes.helpText}>
          {config.helpText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
