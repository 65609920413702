import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

import Component from "../component";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
  primary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
  },
  black: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  },
  white: {
    color: theme.palette.common.gray90,
    backgroundColor: theme.palette.common.black,
  },
  "light-gray": {
    color: theme.palette.common.gray90,
    backgroundColor: theme.palette.common.lightGray,
  },
  "dark-gray": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.gray90,
  },
  danger: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.danger,
  },
  warning: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.warning,
  },
  success: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.success,
  },
  hasBackgroundColor: {
    border: "none",
  },
  cardContent: {
    backgroundColor: theme.palette.common.white,
  },
}));

export default function CustomCard(props) {
  const { data, componentNumber } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `card-${componentNumber}`,
    classes: "",
    header: "",
    footer: "",
    backgroundColor: "",
    cardContentItem: {
      type: "span",
      data: {},
    },
    isDisableTypography: true,
  };
  const config = { ...defaultProps, ...data };
  config.backgroundColorClass = config.backgroundColor
    ? `${classes.hasBackgroundColor} ${classes[config.backgroundColor]}`
    : "";

  return (
    <Card className={`${classes.root} ${config.classes}`}>
      {config.header && (
        <CardHeader
          title={config.header}
          disableTypography={config.isDisableTypography}
          className={config.backgroundColorClass}
        />
      )}
      <CardContent className={classes.cardContent}>
        <Component
          type={config.cardContentItem.type}
          data={config.cardContentItem.data}
        />
      </CardContent>
      {config.footer && (
        <CardActions className={config.backgroundColorClass}>
          {config.footer}
        </CardActions>
      )}
    </Card>
  );
}
