import React from "react";

import CustomTypography from "./atoms/customTypography";
import CustomGrid from "./atoms/customGrid";
import CustomAlert from "./atoms/customAlert";
import CustomBreadcrumb from "./atoms/customBreadcrumb";
import CustomBadge from "./atoms/customBadge";
import Panel from "./atoms/panel";
import CustomList from "./atoms/customList";
import CustomInput from "./atoms/customInput";
import CustomSelect from "./atoms/customSelect";
import CustomCheckbox from "./atoms/customCheckbox";
import CustomRadio from "./atoms/customRadio";
import CustomSwitch from "./atoms/customSwitch";
import CustomToggle from "./atoms/customToggle";
import Datepicker from "./atoms/datepicker";
import CustomCard from "./atoms/customCard";
import Image from "./atoms/image";
import CustomTabs from "./atoms/customTabs";
import CustomIcon from "./atoms/customIcon";
import CustomButton from "./atoms/customButton";
import CustomModal from "./atoms/customModal";

import CustomForm from "./molecules/customForm";
import CustomUpload from "./molecules/customUpload";

import ReCAPTCHA from "./atoms/recaptcha";

let componentNumber = 0;

export default function Component(props) {
  const renderInput = () => {
    if (!props.type) {
      return false;
    } else if (!props.data && props.type !== "divider") {
      return false;
    }
    ++componentNumber;
    const { type } = props;
    const newProps = { ...props, componentNumber };
    if (type === "heading" || type === "paragraph" || type === "divider") {
      return <CustomTypography {...newProps} />;
    } else if (type === "image") {
      return <Image {...newProps} />;
    } else if (type === "grid") {
      return <CustomGrid {...newProps} />;
    } else if (type === "alert") {
      return <CustomAlert {...newProps} />;
    } else if (type === "breadcrumb") {
      return <CustomBreadcrumb {...newProps} />;
    } else if (type === "badge") {
      return <CustomBadge {...newProps} />;
    } else if (type === "form") {
      return <CustomForm {...newProps} />;
    } else if (type === "panel") {
    } else if (type === "upload") {
      return <CustomUpload {...newProps} />;
    } else if (type === "panel") {
      return <Panel {...newProps} />;
    } else if (type === "list") {
      return <CustomList {...newProps} />;
    } else if (type === "input") {
      return <CustomInput {...newProps} />;
    } else if (type === "date") {
      return <Datepicker {...newProps} />;
    } else if (type === "select") {
      return <CustomSelect {...newProps} />;
    } else if (type === "checkbox") {
      return <CustomCheckbox {...newProps} />;
    } else if (type === "radio") {
      return <CustomRadio {...newProps} />;
    } else if (type === "switch") {
      return <CustomSwitch {...newProps} />;
    } else if (type === "card") {
      return <CustomCard {...newProps} />;
    } else if (type === "tabs") {
      return <CustomTabs {...newProps} />;
    } else if (type === "toggle") {
      return <CustomToggle {...newProps} />;
    } else if (type === "icon") {
      return <CustomIcon {...newProps} />;
    } else if (type === "button") {
      return <CustomButton {...newProps} />;
    } else if (type === "modal") {
      return <CustomModal {...newProps} />;
    } else if (type === "recaptcha") {
      return <ReCAPTCHA {...newProps} />;
    } else {
      console.error("Type cannot be identified");
      return null;
    }
  };
  return <React.Fragment>{renderInput()}</React.Fragment>;
}
