import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    padding: ".25em .6em",
    fontWeight: 700,
    lineHeight: 1,
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "text-bottom",
    borderRadius: "10rem",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  primary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
  },
  black: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  },
  white: {
    color: theme.palette.common.gray90,
    backgroundColor: theme.palette.common.black,
  },
  "light-gray": {
    color: theme.palette.common.gray90,
    backgroundColor: theme.palette.common.lightGray,
  },
  "dark-gray": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.gray90,
  },
  danger: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.danger,
  },
  warning: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.warning,
  },
  success: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.success,
  },
}));

export default function CustomBadge(props) {
  const { data, componentNumber } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `badge-${componentNumber}`,
    classes: "",
    element: "span",
    content: "",
    color: "primary",
  };
  const config = { ...defaultProps, ...data };

  return (
    <Typography
      variant={config.element}
      className={`${classes.root} ${classes[config.color]} ${config.classes}`}
      id={config.id}
    >
      {config.content}
    </Typography>
  );
}
