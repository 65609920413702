import { useState } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/styles";

import { Capitalize } from "../../utils/capitalize";

const useStyles = makeStyles((theme) => ({
  label: { marginBottom: ".5rem", fontSize: ".875rem" },
  labelHidden: { visibility: "hidden" },
  toggleBtnClass: { minWidth: "2.66rem", padding: "8.3px" },
  helpText: { marginLeft: 0 },
}));

export default function CustomToggle(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `toggle-${componentNumber}`,
    classes: "",
    defaultValue: "",
    isDefaultWidth: true,
    isCentered: false,
    helpText: "",
    label: "",
    isLabelHidden: false,
    toggleItems: [
      {
        key: "",
        displayName: "",
      },
    ],
  };
  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  config.helpTextId = `helper-text-${config.id}`;
  config.labelHiddenClass = config.isLabelHidden ? classes.labelHidden : "";
  config.events = {
    onChangeEvent: `onChange${capitalId}`,
  };

  const [state, setState] = useState(config.defaultValue);

  function handleOnChange(event, newState) {
    setState(newState);
    const eventData = {
      detail: {
        config,
      },
    };
    callbackFn(config.events.onChangeEvent, eventData);
  }

  return (
    <FormControl fullWidth={!config.isDefaultWidth} className={config.classes}>
      {config.label && (
        <label
          htmlFor={config.id}
          className={`${classes.label} ${config.labelHiddenClass}`}
        >
          {config.label}
        </label>
      )}
      <ToggleButtonGroup value={state} exclusive onChange={handleOnChange}>
        {config.toggleItems.map((toggleItem, index) => (
          <ToggleButton
            disableRipple
            key={index}
            value={toggleItem.key}
            className={classes.toggleBtnClass}
          >
            {toggleItem.displayName}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {config.helpText && (
        <FormHelperText id={config.helpTextId} className={classes.helpText}>
          {config.helpText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
