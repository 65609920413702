import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
}));

export default function Image(props) {
  const { data, componentNumber } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `image-${componentNumber}`,
    classes: "",
    src: "",
    title: "",
    altText: "",
  };
  const config = { ...defaultProps, ...data };

  const [state, setState] = useState("");

  import(`../../assets/${config.src}`).then((image) => {
    setState(image.default);
  });

  return (
    <img
      id={config.id}
      className={`${config.classes} ${classes.img}`}
      src={state}
      title={config.title}
      alt={config.altText}
    />
  );
}
