export const colorConstants = {
  teal: "#28807C",
  blue: "#0077A7",
  black: "#000000",
  white: "#FFFFFF",
  darkGray: "#323E43",
  medGray: "#8EA6AF",
  lightGray: "#EAEAEA",
  gray90: "#1D2528",
  gray70: "#51646D",
  gray60: "#728A92",
  gray40: "#C4CED1",
  gray30: "#E3E3E3",
  gray10: "#F7F7F7",
  lightTeal: "#31A09D",
  lightBlue: "#008EC6",
  red: "#B80000",
  orange: "#C75300",
  green: "#158655",
  darkBlue: "#005FCC",
};

export const gradientConstants = {
  brandGradient: "linear-gradient(135.37deg,#28807C,#0077A7)",
};
