import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReCAPTCHA from "react-google-recaptcha";
import { Capitalize } from "../../utils/capitalize";
const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
  recaptchaCls : { display: "inline-block" },
  container: {
    padding: "30px",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.grey[400]}`,
    textAlign: "center",
  },
  label: { fontSize: ".875rem" },
  helpText: { marginLeft: 0 },
  labelHidden: { visibility: "hidden" },
}));

const TEST_SITE_KEY = "6Lc5TvcbAAAAAL9yP7-xODB5zxXbfSfE6YDeJM51";
const DELAY = 1500;

export default function Recaptcha(props) {
  const { data, componentNumber,callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `recaptcha-${componentNumber}`,
    classes: "",
    name:`recaptcha-${componentNumber}`,
    theme: "light"
  };
  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  config.events = {onChangeEvent: `onChange${capitalId}`};
  const [state, setState] = useState("");

  const handleChange = (value) => {
    callbackFn(config.events.onChangeEvent,value)
    setState({ value });
    // if value is null recaptcha expired
    if (value === null) setState({ expired: "true" });
  };

  const  asyncScriptOnLoad = () => {
    setState({ callback: "called!" });
  };

  return (
    <ReCAPTCHA
           theme={config.theme}
      sitekey={TEST_SITE_KEY}
      onChange={ handleChange}
      asyncScriptOnLoad={asyncScriptOnLoad}
    />
  );
}


