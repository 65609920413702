import React from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import footer from "../../configurations/componentconfigurations/footer.json";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.gray10,
    padding: ".5rem 1rem",
    flexShrink: 0,
  },
  footerLinksContainer: {
    marginLeft: "auto",
  },
  footerLink: {
    marginRight: "1rem",
    fontSize: ".6875rem",
    lineHeight: "inherit",
    whiteSpace: "nowrap",
    color: theme.palette.common.black,
    marginBottom: 0,
    fontWeight: 600,
  },
  dark: {
    background: theme.palette.common.gray10,
  },
  darkFooterLink: {
    color: theme.palette.common.black,
  },
}));

export default function Footer(props) {
  const classes = useStyles();

  const [version, setVersion] = React.useState("");
  const setbuildVersion = (text) => {
    setVersion(text);
  };

  function fetchVersion (){
    if(version === ''){
      const requestOptions = {
        method: "GET",
        headers: { 
          "Content-Type": "application/json", 
          "Access-Control-Allow-Origin": "*", 
        },
      };
      fetch(`/build_version.json`, requestOptions)
      .then(resp => resp.json())
      .then(data => {
        setbuildVersion(data.BuildID);
      });
    }
  };

  const linksToBeUpdated = footer.links.map((link, index) => {
    fetchVersion();
    return (
      <Link
        key={index}
        href={link.redirectTo}
        rel="noopener noreferrer"
        target="_blank"
        className={`${classes.footerLink} ${
          footer.isDark ? classes.darkFooterLink : ""
        }`}
      >
        {link.label}
      </Link>
    );
  });

  return (
    <footer className={`${classes.root} ${footer.isDark ? classes.dark : ""}`}>
      <Grid container direction="row">
        <Grid item>
          <Typography>
            <Link
              href="https://www.michigan.gov/"
              rel="noopener noreferrer"
              target="_blank"
              className={`${classes.footerLink} ${
                footer.isDark ? classes.darkFooterLink : ""
              }`}
            >
              {footer.copyrightText}
            </Link>
          </Typography>
        </Grid>
        <Grid item className={classes.footerLinksContainer}>
          <Typography>
            {linksToBeUpdated}
            <Link
              href="#"
              className={`${classes.footerLink} ${
                footer.isDark ? classes.darkFooterLink : ""
              }`}
            >
              <span>Version {version}</span>
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}
