import { useState } from "react";

import home from "../../configurations/immunization/home.json";
import Page from "../../components/molecules/page";

export default function HomePage() {
  const [state] = useState(home);
  import(`./../../styles/home.scss`);

  return <Page {...state} callbackFn={callbackFn} isSecure={false}/>;

  function callbackFn(eventType, data) {
    switch (eventType) {
      default:
        console.log(`no event type exists of name: ${eventType}`);
    }
  }
}