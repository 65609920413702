import { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { Capitalize } from "../../utils/capitalize";

const useStyles = makeStyles((theme) => ({
  label: { marginBottom: ".5rem", fontSize: ".875rem" },
  helpText: { marginLeft: 0 },
  autocompleteInput: { padding: "0.2rem !important" },
}));

export default function CustomSelect(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `select-${componentNumber}`,
    name: `select-${componentNumber}`,
    label: "",
    classes: "",
    helpText: "",
    emptyText: "Select",
    placeholder: "",
    isDefaultWidth: true,
    isRequired: false,
    isLabelHidden: false,
    isAutocomplete: false,
    isMultiple: false,
    isDisabled: false,
    items: [
      {
        displayName: "Display Name",
        key: "UniqueKey",
        isDefault: false,
        isDisabled: false,
      },
    ],
    variant: "outlined",
  };
  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  config.helpTextId = `helper-text-${config.id}`;
  config.labelHiddenClass = config.isLabelHidden ? classes.labelHidden : "";
  config.events = {
    onChangeEvent: `onChange${capitalId}`,
  };

  const defaultEl = config.items.filter((item) => item.isDefault);
  const defaultState = defaultEl.length ? defaultEl[0] : "";
  const [value, setValue] = useState(defaultState);

  function handleOnChange(event) {
    setValue(event.target.value);
    const eventData = {
      detail: {
        config,
      },
    };
    callbackFn(config.events.onChangeEvent, eventData);
  }

  function renderSelectBox() {
    return (
      <Select
        native
        id={config.id}
        variant={config.variant}
        aria-describedby={config.helpTextId}
        value={value}
        onChange={handleOnChange}
        required={config.isRequired}
        name={config.name}
        multiple={config.isMultiple}
        disabled={config.isDisabled}
      >
        {config.displayEmpty && <option value="">{config.emptyText}</option>}
        {config.items.map((item, index) => {
          return (
            <option value={item.key} key={index} disabled={item.isDisabled}>
              {item.displayName}
            </option>
          );
        })}
      </Select>
    );
  }

  function renderAutocomplete() {
    return (
      <Autocomplete
        id={config.id}
        options={config.items}
        onChange={handleOnChange}
        getOptionLabel={(option) => option.displayName}
        classes={{ input: classes.autocompleteInput }}
        disabled={config.isDisabled}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant={config.variant}
              placeholder={config.placeholder}
              required={config.isRequired}
              value={value}
              aria-describedby={config.helpTextId}
              name={config.name}
            />
          );
        }}
      />
    );
  }

  return (
    <FormControl fullWidth={!config.isDefaultWidth} className={config.classes}>
      {config.label && (
        <label
          htmlFor={config.id}
          className={`${classes.label} ${config.labelHiddenClass}`}
        >
          {config.label}
        </label>
      )}

      {!config.isAutocomplete && renderSelectBox()}
      {config.isAutocomplete && renderAutocomplete()}

      {config.helpText && (
        <FormHelperText id={config.helpTextId} className={classes.helpText}>
          {config.helpText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
