import { useState, useEffect, Fragment } from "react";
import axios from "axios";
import moment from "moment";
import parser from "fast-xml-parser";
import he from "he";

import parseFullName from "parse-full-name";
import states from "us-state-codes";

import immunization from "../../configurations/immunization/immunization.json";
import Page from "../../components/molecules/page";
import {
  useFstName,
  useLstName,
  useGender,
  useDobDate,
  useDobDateMPI,
  useDocNumber,
  useDocExpDate,
  useAddress,
  useSsnNum,
  usePhnNum,
  useDemographics,
  useDocType,
  useDocExpDateMPI,
  useMdlName,
  useSuffix,
  useDocRegion,
  useValidation,
  useEnableSubmission,
  useActivity,
} from "../../hooks/custom-hooks";

export default function ImmunizationUploadPage(props) {
  const [state, setState] = useState(immunization);
  const {
    getDemographics,
    updateDemographics,
  } = useDemographics();

  const { updateActiveState } = useActivity();

  const { getFstName, updateFstName, resetFstName } = useFstName();
  const { getMdlName, updateMdlName, resetMdlName } = useMdlName();
  const { getLstName, updateLstName, resetLstName } = useLstName();
  const { getSuffix, updateSuffix, resetSuffix } = useSuffix();
  const { getGender, updateGender, resetGender } = useGender();
  const { updateDobDate, resetDobDate } = useDobDate();
  const { getDobDateMPI, updateDobDateMPI, resetDobDateMPI } = useDobDateMPI();
  const { getDocExpDate, updateDocExpDate, resetDocExpDate } = useDocExpDate();
  const { getDocExpDateMPI, updateDocExpDateMPI, resetDocExpDateMPI } =
    useDocExpDateMPI();
  const { getDocNumber, updateDocNumber, resetDocNumber } = useDocNumber();
  const { getDocType, updateDocType, resetDocType } = useDocType();
  const { getDocRegion, updateDocRegion, resetDocRegion } = useDocRegion();
  const { getSsnNum, updateSsnNum, resetSsnNum } = useSsnNum();
  const { getPhnNum, updatePhnNum, resetPhnNum } = usePhnNum();

  const {
    getAddress,
    updateAddress,
    resetAddress,
    updateValidateAddress,
    getMpiAddrOne,
    getMpiCity,
    getMpiState,
    getMpiZip,
    updateMpiAddressOne,
    updateMpiCity,
    updateMpiState,
    updateMpiZip,
    getParsedStreet,
    updateParsedStreet,
    resetParsedStreet,
    updateParsedCity,
    getParsedCity,
    resetParsedCity,
    getParsedState,
    updateParsedState,
    resetParsedState,
    getParsedZip,
    updateParsedZip,
    resetParsedZip,
    getParsedCountry,
    resetParsedCountry,
    parseFullAddress,
  } = useAddress();

  const {
    getSSNStatus,
    updateSSNStatus,
    resetSSNStatus,
    getPHNStatus,
    updatePHNStatus,
    resetPHNStatus,
  } = useValidation();

  const {
    updateIsRecaptcha,
    getIsRecaptcha,
    updateConsent,
    getConsent,
  } = useEnableSubmission();

  /**
   * Constants - Azure prebuild trained model
   */
  const docTypePassport = "idDocument.passport";
  const docTypeDriverLicense = "idDocument.driverLicense";
  const stateId =  "idDocument.nationalIdentityCard";

  const initVal = "";
  const globalUrl = ``; // for Local https://mdhhsmiimmsportal-dev.state.mi.us

  const {
    userfirstname,
    userlastname,
    usermail,
    username,
    logouturl,
    requestid
  } = getDemographics();
  useEffect(() => {
    if(!logouturl && !username){
      let config = {
        method: "GET",
        url: `${globalUrl}/immunization`,
        headers: {
          "Accept": "*/*",
        },
      };
      axios(config)
      .then((response) => response)
      .then((resp) => {
        if(resp.status === 200 && resp.statusText === "OK"){
          return resp.headers;
        } else {
          return null;
        }
      })
      .then((headers) => {
        updateDemographics(headers);
      })
      .catch((error) => {
        //TODO - write catch code to log exceptions.
      })
    } else {
      //TODO - write code to handle null values in logout and username.
    }
  }, [state]);

  //Address Validation is address field has a value - this will be possible for only DL or state ID
  async function validateAddress() {
    setState((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    const rebuildURLProd = `${globalUrl}/immunization/api/addressvalidation`;
    let parsedSteet = getParsedStreet() ? getParsedStreet() : "";
    let parsedCity = getParsedCity() ? getParsedCity() : "";
    let parsedState = getParsedState() ? getParsedState() : "";
    let parsedZip = getParsedZip() ? getParsedZip() : "";
    let parsedCountry = getParsedCountry() ? getParsedCountry() : "";
    var data = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:inp="http://businessobjects.com/service/WS_AddrCleansing/input">\r\n<soapenv:Header/>\r\n<soapenv:Body>\r\n<inp:dataset>\r\n<record>\r\n<address_line1>${parsedSteet}</address_line1>\r\n<address_line2></address_line2>\r\n<city>${parsedCity}</city>\r\n<state>${parsedState}</state>\r\n<zip>${parsedZip}</zip>\r\n<country>${parsedCountry}</country>\r\n<disable_suggestion_lists>N</disable_suggestion_lists>\r\n</record>\r\n</inp:dataset>\r\n</soapenv:Body>\r\n</soapenv:Envelope>`;
    let config = {
      method: "POST",
      url: rebuildURLProd,
      headers: {
        "content-type": "text/xml;charset=UTF-8",
        SOAPAction: "service=WS_AddrCleansing",
      },
      data: data,
    };
    await axios(config)
      .then((response) => {
        let addressValidationResultsReturned = false;
        if (response.status === 200 && response.statusText === "OK") {
          let responseData = response.data;
          if (responseData) {
            let options = {
              attributeNamePrefix: "@_",
              attrNodeName: "attr",
              textNodeName: "#text",
              ignoreAttributes: true,
              ignoreNameSpace: false,
              allowBooleanAttributes: false,
              parseNodeValue: true,
              parseAttributeValue: false,
              trimValues: true,
              cdataTagName: "__cdata",
              cdataPositionChar: "\\c",
              parseTrueNumberOnly: false,
              arrayMode: false,
              attrValueProcessor: (val, attrName) =>
                he.decode(val, { isAttributeValue: true }),
              tagValueProcessor: (val, tagName) => he.decode(val),
              stopNodes: ["parse-me-as-string"],
            };
            let tObj = parser.getTraversalObj(responseData, options);
            let jsonObj2 = parser.convertToJson(tObj, options);
            let cleanAddL1 = "";
            let cleanAddL2 = "";
            let cleanCity = "";
            let cleanState = "";
            let cleanZip5 = "";
            let cleanZip4 = "";
            let cleanCountry = "";
            let cleansedAddress = "";
            if (
              jsonObj2 !== null &&
              jsonObj2 !== undefined &&
              jsonObj2 !== ""
            ) {
              if (
                jsonObj2["soapenv:Envelope"] !== null &&
                jsonObj2["soapenv:Envelope"] !== undefined &&
                jsonObj2["soapenv:Envelope"]["soapenv:Body"] !== null &&
                jsonObj2["soapenv:Envelope"]["soapenv:Body"] !== undefined &&
                jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"] !==
                null &&
                jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"] !==
                undefined &&
                jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                "record"
                ] !== null &&
                jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                "record"
                ] !== undefined &&
                jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                "record"
                ]["address_fields"] !== null &&
                jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                "record"
                ]["address_fields"] !== undefined
              ) {
                if (
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== null &&
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== undefined
                ) {
                  cleanAddL1 =
                    jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                    "record"
                    ]["address_fields"]["ac_primary_address"];
                }
                if (
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== null &&
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== undefined
                ) {
                  cleanAddL2 =
                    jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                    "record"
                    ]["address_fields"]["ac_secondary_address"];
                }
                if (
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== null &&
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== undefined
                ) {
                  cleanCity =
                    jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                    "record"
                    ]["address_fields"]["ac_locality1_name"];
                }
                if (
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== null &&
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== undefined
                ) {
                  cleanState =
                    jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                    "record"
                    ]["address_fields"]["ac_region1"];
                }
                if (
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== null &&
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== undefined
                ) {
                  cleanZip5 =
                    jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                    "record"
                    ]["address_fields"]["ac_postcode1"];
                }
                if (
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== null &&
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== undefined
                ) {
                  cleanZip4 =
                    jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                    "record"
                    ]["address_fields"]["ac_postcode2"];
                }
                if (
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== null &&
                  jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                  "record"
                  ]["address_fields"]["ac_primary_address"] !== undefined
                ) {
                  cleanCountry =
                    jsonObj2["soapenv:Envelope"]["soapenv:Body"]["dataset"][
                    "record"
                    ]["address_fields"]["ac_country_name"];
                }
                if (
                  cleanAddL1 !== "" &&
                  cleanCity !== "" &&
                  cleanState !== "" &&
                  cleanZip5 !== "" &&
                  cleanZip4 !== ""
                ) {
                  cleansedAddress = cleanAddL1
                    .concat(" ", cleanAddL2)
                    .concat("\n ", cleanCity)
                    .concat(" ", cleanState)
                    .concat("  ", cleanZip5)
                    .concat("-", cleanZip4)
                    .concat("\n ", cleanCountry);
                  updateMpiAddressOne(`${cleanAddL1} ${cleanAddL2}`);
                  updateMpiCity(cleanCity);
                  updateMpiState(cleanState);
                  updateMpiZip(`${cleanZip5}-${cleanZip4}`);
                  updateValidateAddress(cleansedAddress);
                } else {
                  updateMpiAddressOne(initVal);
                  updateMpiCity(initVal);
                  updateMpiState(initVal);
                  updateMpiZip(initVal);
                  updateValidateAddress(initVal);
                }
                if (cleansedAddress) {
                  addressValidationResultsReturned = true;
                  hideMessagePop();
                  addressVerification(cleansedAddress);
                } else {
                  let eventPerformed = ``;
                  eventPerformed = `event performed : getrecord :: no response from address cleansing API`;
                  logEvent(username, eventPerformed, requestid);
                  getMcirID(true);
                  uncheckConsent();
                }
              } else {
                let eventPerformed = ``;
                eventPerformed = `event performed :getrecord :: no response from address cleansing API`;
                logEvent(username, eventPerformed, requestid);
                getMcirID(true);
                uncheckConsent();
              }
            }
          } else {
            warningMessagePopUp(
              "info",
              "We were unable to validate your address"
            );
          }
          if(addressValidationResultsReturned){
            setState((prevState) => {
              return {
                ...prevState,
                isLoading: false,
              };
            });
          }
        }
      })
      .catch((error) => {
        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
  }

  /**
   * 
   */
  //MPI call - if more than 1 record is returend from MPI then ask SSN & Phone
  async function getMcirID(enteredOrValidated) {
    closeAddressVerification();
    const { requestid } = getDemographics();
    setState((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    const onMFisrt = getFstName();
    const onMLast = getLstName();
    const dobDateVal = getDobDateMPI();
    const genderAttrVal = getGender();
    const rebuildURLProd = `${globalUrl}/immunization/api/getrecord`;

    /**
     * Creating request object for MPI Call.
     */
    var requestObject = {};
    requestObject.genericRecordId = {};
    requestObject.genericRecordId.srcCode = "IMMR";
    // requestObject.genericRecordId.memIdnum = requestid;
    requestObject.attrListMap = {};

    /**
     * Creating Name field in MPI request object
     */
    requestObject.attrListMap.NAME = [];
    requestObject.attrListMap.NAME[0] = {};
    requestObject.attrListMap.NAME[0].fieldMap = {};
    requestObject.attrListMap.NAME[0].fieldMap.onmfirst = onMFisrt;
    requestObject.attrListMap.NAME[0].fieldMap.onmlast = onMLast;

    /**
     * Creating DOB field in MPI request object
     */

    requestObject.attrListMap.DOB = [];
    requestObject.attrListMap.DOB[0] = {};
    requestObject.attrListMap.DOB[0].fieldMap = {};
    requestObject.attrListMap.DOB[0].fieldMap.dateval = dobDateVal;

    /**
     * Creating GENDER field in MPI request object
     */
    requestObject.attrListMap.GENDER = [];
    requestObject.attrListMap.GENDER[0] = {};
    requestObject.attrListMap.GENDER[0].fieldMap = {};
    requestObject.attrListMap.GENDER[0].fieldMap.attrval =
      genderAttrVal.charAt(0);

    let docType = getDocType();

    requestObject.attrListMap.ADDR = [];
    requestObject.attrListMap.ADDR[0] = {};
    requestObject.attrListMap.ADDR[0].fieldMap = {};
    if(enteredOrValidated){
      requestObject.attrListMap.ADDR[0].fieldMap.stline1 = getParsedStreet();
      requestObject.attrListMap.ADDR[0].fieldMap.city = getParsedCity();
      requestObject.attrListMap.ADDR[0].fieldMap.state = states.sanitizeStateCode(getParsedState());
      requestObject.attrListMap.ADDR[0].fieldMap.zipcode = getParsedZip();
    } else {
      requestObject.attrListMap.ADDR[0].fieldMap.stline1 = getMpiAddrOne();
      requestObject.attrListMap.ADDR[0].fieldMap.city = getMpiCity();
      requestObject.attrListMap.ADDR[0].fieldMap.state = states.sanitizeStateCode(getMpiState());
      requestObject.attrListMap.ADDR[0].fieldMap.zipcode = getMpiZip();
    }

    if (docType === docTypeDriverLicense) {
      /**
       * Creating GENDER field in MPI request object
       */
      requestObject.attrListMap.DLID = [];
      requestObject.attrListMap.DLID[0] = {};
      requestObject.attrListMap.DLID[0].fieldMap = {};
      requestObject.attrListMap.DLID[0].fieldMap.idnumber = getDocNumber();
      if (
        getDocExpDateMPI() !== null &&
        getDocExpDateMPI() !== undefined &&
        getDocExpDateMPI() !== ""
      ) {
        requestObject.attrListMap.DLID[0].fieldMap.idexpdate =
          getDocExpDateMPI();
      }
      requestObject.attrListMap.DLID[0].fieldMap.idissuer = getDocRegion();
    }

    if (
      getSsnNum() !== null &&
      getSsnNum() !== undefined &&
      getSsnNum() !== ""
    ) {
      /**
       * Creating SSN field in MPI request object
       */
      requestObject.attrListMap.SSN = [];
      requestObject.attrListMap.SSN[0] = {};
      requestObject.attrListMap.SSN[0].fieldMap = {};
      requestObject.attrListMap.SSN[0].fieldMap.idnumber = getSsnNum();
      requestObject.attrListMap.SSN[0].fieldMap.idissuer = "SSA";
    }

    if (
      getPhnNum() !== null &&
      getPhnNum() !== undefined &&
      getPhnNum() !== ""
    ) {
      /**
       * Creating SSN field in MPI request object
       */
      requestObject.attrListMap.PHONE = [];
      requestObject.attrListMap.PHONE[0] = {};
      requestObject.attrListMap.PHONE[0].fieldMap = {};
      requestObject.attrListMap.PHONE[0].fieldMap.pharea = getPhnNum()
        .split(" ")
        .join("")
        .substr(2, 3);
      requestObject.attrListMap.PHONE[0].fieldMap.phnumber = getPhnNum()
        .split(" ")
        .join("")
        .substr(5, 7);
    }

    var data = JSON.stringify(requestObject);
    var config = {
      method: "POST",
      url: rebuildURLProd,
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "JSON",
      },
      data: data,
    };

    /**
     * 
     */
    let MPIResp = await axios(config)
      .then((response) => {
        if (response.status === 200 && response.statusText === "OK"){
          return response.data;
        } else {
          showNoRecordFoundModal();
        }
      })
      .then((data) => {
        if (data.ResponseMetadata.responseCode === "200 OK" && data.ResponseMetadata.responseText !== ""){
          
          const base64URL = data.ResponseMetadata.responseText;
          const binary = atob(base64URL.replace(/\s/g, ''));
          const len = binary.length;
          const buffer = new ArrayBuffer(len);
          const view = new Uint8Array(buffer);
          for (let i = 0; i < len; i += 1) {
            view[i] = binary.charCodeAt(i);
          }
    
          // create the blob object with content-type "application/pdf"
          const blob = new Blob([view], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
    
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = url;
          a.download = 'ImmunizationConsumer.pdf';
          //a.target = '_blank';
          a.click();
          
          
         /* const link = document.createElement("a");
          link.setAttribute(
            "href",
            `data:application/pdf;base64,${data.ResponseMetadata.responseText}`
          );
          link.setAttribute("download", "ImmunizationConsumer.pdf");
         // link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();*/
          warningMessagePopUp(
            "success",
            "Your immunization record is available for download"
          );
        } else {
          showNoRecordFoundModal();
        }
        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      })
      .catch((error) => {
        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
        showNoRecordFoundModal();
      });
  }

  function ageReqSatisfied(value) {
    let date = new Date();
    let valDate = new Date(value);
    valDate.setFullYear(valDate.getFullYear() + 18);
    if (isNaN(valDate)) {
      return true;
    } else if (
      valDate !== null &&
      valDate !== undefined &&
      valDate.getTime() > date.getTime()
    ) {
      return true;
    } else {
      return false;
    }
  }

  function documentExpired(value) {
    let date = new Date();
    let valDate = new Date(value);
    if (
      valDate !== null &&
      valDate !== undefined &&
      !isNaN(valDate) &&
      valDate.getTime() < date.getTime()
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Calling the Azure API start
  const onUploadingPhotoIdDoc = async (sFile) => {
    await hideMessagePop();
    setState((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    let file = sFile.target?.files?.length
      ? sFile.target?.files[0]
      : sFile.dataTransfer?.files?.length
      ? sFile.dataTransfer?.files[0]
      : null;
    if (file) {
      if (file.size > 6000000) {
        warningMessagePopUp(
          "error",
          "The image you are uploading is too large.  Please make sure the image is less than 6 MB."
        );
        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
        return;
      }
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          isLoading: false,
        };
      });
      return;
    }
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf" ||
      file.type === "image/jpg"
    ) {
      let reader = new FileReader();
      reader.onload = function (e) {
        let contents = e.target.result;
        readDocument(contents, file.type);
      };
      reader.readAsDataURL(file);
      hideMessagePop();
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          isLoading: false,
        };
      });
      warningMessagePopUp(
        "error",
        "This system will only support files types of png, jpg, jpeg, and pdf.  Please upload using one of these formats."
      );
    }
  };

  function getDataFromObject(data) {
    //if(data != undefined)
   // {
      if (data.type === "string") {
        if(!data.valueString){
          return data.content;
        }
        return data.valueString;
      } else if (data.type === "countryRegion") {
        return data.valueCountryRegion;
      } else if (data.type === "date") {
        return data.valueDate;
      }else if (data.type === "address") {
        return data.content;
      }
   // }
    
  }

  async function call2ndAzure(azure2ndURL) {
    var config2ndCall = {
      method: "GET",
      url: azure2ndURL,
      headers: {},
    };
    axios(config2ndCall)
      .then(async (response) => {
        if (
          response.data.status === "running" ||
          response.data.status === "notStarted"
        ) {
          setTimeout(() => {
            call2ndAzure(azure2ndURL);
          }, 1000);
        } else {
          //TODO: look for no result
          if (response?.data?.analyzeResult?.documents?.length !== 0) {
            let docResult = response.data.analyzeResult.documents[0];
            let name = "";
            let azurefstName = "";
            let azurelstName = "";
            let tempgender = "";
            let tempdobDate = "";
            let tempdobDateMPI = "";
            let tempdocExpDate = "";
            let tempdocNumber = "";
            let tempaddress = "";
            let tempRegion = "";
            let parsedFstName = "";
            let parsedMdlName = "";
            let parsedLstName = "";
            let valueObj;
            if (docResult?.docType === docTypePassport) {
              updateDocType(docResult.docType);
              if (docResult?.fields?.MachineReadableZone?.valueObject) {
                valueObj = docResult?.fields?.MachineReadableZone?.valueObject;
              } else if (docResult?.fields?.MachineReadableZone?.value) {
                valueObj = docResult?.fields?.MachineReadableZone?.value;
              } else {
                setState((prevState) => {
                  return {
                    ...prevState,
                    isLoading: false,
                  };
                });
                warningMessagePopUp(
                  "error",
                  "The ID you are uploading is not clear, please rescan and upload again."
                );
                return;
              }
              const {
                FirstName,
                LastName,
                Sex,
                DocumentNumber,
                DateOfExpiration,
                DateOfBirth,
                CountryRegion
              } = valueObj;
              azurefstName = getDataFromObject(FirstName);
              azurelstName = getDataFromObject(LastName);
              tempgender =
                getDataFromObject(Sex) === "F"
                  ? "Female"
                  : getDataFromObject(Sex) === "M"
                  ? "Male"
                  : getDataFromObject(Sex);
              tempdobDate = moment(getDataFromObject(DateOfBirth)).format(
                "MM/DD/YYYY"
              );
              tempdobDateMPI = moment(getDataFromObject(DateOfBirth)).format(
                "YYYYMMDD"
              );
              tempdocExpDate = moment(
                getDataFromObject(DateOfExpiration)
              ).format("MM/DD/YYYY");
              tempdocNumber = getDataFromObject(DocumentNumber);
              if(docResult.fields.PlaceOfBirth){
              tempaddress = getDataFromObject(docResult.fields.PlaceOfBirth) +", "+ getDataFromObject(CountryRegion);
              }else{
                tempaddress =  getDataFromObject(CountryRegion);
              }
            } else if (docResult.docType === docTypeDriverLicense) {
              updateDocType(docResult.docType);
              if (docResult?.fields) {
                valueObj = docResult.fields;
              } else {
                setState((prevState) => {
                  return {
                    ...prevState,
                    isLoading: false,
                  };
                });
                warningMessagePopUp(
                  "error",
                  "This system will only support files types of png, jpg, jpeg, and pdf.  Please upload using one of these formats."
                );
                return;
              }
              const {
                FirstName,
                LastName,
                Sex,
                DocumentNumber,
                DateOfExpiration,
                DateOfBirth,
                Address,
                Region,
              } = valueObj;
              azurefstName = getDataFromObject(FirstName);
              azurelstName = getDataFromObject(LastName);
              tempgender =
                getDataFromObject(Sex) === "F"
                  ? "Female"
                  : getDataFromObject(Sex) === "M"
                  ? "Male"
                  : getDataFromObject(Sex);
              tempdobDate = moment(getDataFromObject(DateOfBirth)).format(
                "MM/DD/YYYY"
              );
              tempdobDateMPI = moment(getDataFromObject(DateOfBirth)).format(
                "YYYYMMDD"
              );
              tempdocExpDate = moment(
                getDataFromObject(DateOfExpiration)
              ).format("MM/DD/YYYY");
              tempdocNumber = getDataFromObject(DocumentNumber);
              tempaddress = getDataFromObject(Address);
              tempRegion = getDataFromObject(Region);
            }else if(docResult.docType === stateId){
              updateDocType(docResult.docType);
              if (docResult?.fields) {
                valueObj = docResult.fields;
              } else {
                setState((prevState) => {
                  return {
                    ...prevState,
                    isLoading: false,
                  };
                });
                warningMessagePopUp(
                  "error",
                  "This system will only support files types of png, jpg, jpeg, and pdf.  Please upload using one of these formats."
                );
                return;
              }
              const {
                FirstName,
                LastName,
                Sex,
                DocumentNumber,
                DateOfExpiration,
                DateOfBirth,
                Address,
                Region,
              } = valueObj;
              azurefstName = getDataFromObject(FirstName);
              azurelstName = getDataFromObject(LastName);
              tempgender =
                getDataFromObject(Sex) === "F"
                  ? "Female"
                  : getDataFromObject(Sex) === "M"
                  ? "Male"
                  : getDataFromObject(Sex);
              tempdobDate = moment(getDataFromObject(DateOfBirth)).format(
                "MM/DD/YYYY"
              );
              tempdobDateMPI = moment(getDataFromObject(DateOfBirth)).format(
                "YYYYMMDD"
              );
              tempdocExpDate = moment(
                getDataFromObject(DateOfExpiration)
              ).format("MM/DD/YYYY");
              tempdocNumber = getDataFromObject(DocumentNumber);
              tempaddress = getDataFromObject(Address);
            } else {
              updateDocType("Unknown");
              setState((prevState) => {
                return {
                  ...prevState,
                  isLoading: false,
                };
              });
              warningMessagePopUp(
                "error",
                "The image you are uploading must be a drivers license, passport, or state ID."
              );
              return;
            }
            //date of birth validation check
            if (ageReqSatisfied(tempdobDate)) {
              setState((prevState) => {
                return {
                  ...prevState,
                  isLoading: false,
                };
              });
              warningMessagePopUp(
                "error",
                "The age on the ID you are uploading is less than 18 years old.  You must be 18 years or older to obtain your immunization record."
              );
              return;
            } else if (documentExpired(tempdocExpDate)) {
              setState((prevState) => {
                return {
                  ...prevState,
                  isLoading: false,
                };
              });
              warningMessagePopUp(
                "error",
                "The ID you are uploading has expired.  Your ID must be active to obtain your immunization record."
              );
              return;
            } else {
              resetPhnNum();
              resetSsnNum();
              resetSSNStatus();
              resetPHNStatus();
              if(azurelstName && azurelstName.includes("!")){
                azurelstName = azurelstName.replace(/!/g,"I");
              }              
              name = parseFullName.parseFullName(
                `${azurefstName} ${azurelstName}`,
                "all",
                1,
                0,
                0
              );
              parsedFstName = name.first;
              parsedMdlName = name.middle;
              parsedLstName = name.last;
              updateFstName(parsedFstName);
              updateMdlName(parsedMdlName);
              updateLstName(parsedLstName);
              updateSuffix(name.suffix);
              updateGender(tempgender);
              updateDocNumber(tempdocNumber);
              updateDocExpDate(tempdocExpDate);
              updateDobDate(tempdobDate);
              updateDobDateMPI(tempdobDateMPI);
              updateDocRegion(states.getStateCodeByStateName(tempRegion));
              updateAddress(tempaddress);
              hideMessagePop();
              updateFieldsAfterDocUpload(
                parsedFstName,
                parsedMdlName,
                parsedLstName,
                tempgender,
                tempdobDate,
                tempdobDateMPI,
                tempdocExpDate,
                tempdocNumber,
                tempaddress,
                tempRegion
              );
              parseAddressFromAPI(tempaddress);
              changeText("address", { target: { value: tempaddress } });
              setState((prevState) => {
                return {
                  ...prevState,
                  isLoading: false,
                };
              });
            }
          } else {
            setState((prevState) => {
              return {
                ...prevState,
                isLoading: false,
              };
            });
            warningMessagePopUp(
              "error",
              "The ID you are uploading is not clear, please rescan and upload again."
            );
          }
        }
      })
      .catch(function (error) {
        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
        warningMessagePopUp(
          "error",
          "The ID you are uploading is not clear, please rescan and upload again."
        );
      });
  }

  //address parsing API
  function parseAddressFromAPI(data) {
    data=data.replace(/\n/g," ");
    let dataQ = `{"query": "${data}"}`;
    let config = {
      method: "post",
      url: `${globalUrl}/immunization/api/addressparser`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: dataQ,
    };
    axios(config)
      .then(function (response) {
        let addressVal = response.data;
        let house_number = "";
        let road = "";
        let city = "";
        let state = "";
        let postcode = "";
        for (let i = 0; i < addressVal.length; i++) {
          let keys = Object.keys(addressVal[i]);
          for (let j = 0; j < keys.length; j++) {
            if (
              keys[j] === "label" &&
              addressVal[i][keys[j]] === "house_number"
            ) {
              house_number = addressVal[i]["value"];
              break;
            }
            if (keys[j] === "label" && addressVal[i][keys[j]] === "road") {
              road = addressVal[i]["value"];
              break;
            }
            if (keys[j] === "label" && addressVal[i][keys[j]] === "city") {
              city = addressVal[i]["value"];
              break;
            }
            if (keys[j] === "label" && addressVal[i][keys[j]] === "state") {
              state = addressVal[i]["value"];
              break;
            }
            if (keys[j] === "label" && addressVal[i][keys[j]] === "postcode") {
              postcode = addressVal[i]["value"];
              break;
            }
          }
        }
        updateParsedStreet(house_number + " " + road);
        updateParsedCity(city);
        updateParsedState(state);
        updateParsedZip(postcode);
      })
      .catch(function (error) {

      });
  }

  //Azure document reading method
  async function readDocument(contentData, fileType) {
    let azureImageAnalizeURL = `${globalUrl}/immunization/api/analyze/image`;
    var config = {
      method: "POST",
      url: azureImageAnalizeURL,
      headers: {
        //"Content-Transfer-Encoding": fileType,
        "Content-Type": 'application/json',
      },
      data: { "base64Source": contentData.split(',')[1]},
    };
    axios(config)
      .then((response) => {
        let azure2ndURL = `${globalUrl}/immunization/api/analyze/result${response.headers["apim-request-id"]}?api-version=2022-08-31`;
        call2ndAzure(azure2ndURL);
      })
      .catch((error) => {
        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
        warningMessagePopUp(
          "error",
          "The ID you are uploading is not clear, please rescan and upload again."
        );
      });
  }

  //"Uploaded DL,fName:JUNE,lName:SAMPLE, DocNum: $100100100100"
  const logEvent = (userID, eventDetails, miSessionId) => {
    // let userID = demographics?.userName;
    // if(demographics === null || demographics === undefined){
    //   let demographicsURL = `${globalUrl}/immunization`;
    //   var demoConfig = {
    //     method: "GET",
    //     url: demographicsURL,
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //   };
    //   fetch(demoConfig)
    //   .then(res => {
    //     return res.json()
    //   })
    //   .then(data => {
    //     updateDemographics(data);
    //   })
    //   .catch((error) => {
    //   });
    // // } else {
    // }
    const urlEvent = `${globalUrl}/immunization/api/logevent`;
    let sysDate = new Date();
    let lsData = JSON.stringify({
      userName: userID,
      eventDesc: eventDetails,
      sessionId: miSessionId,
      date: sysDate,
      environment: "DEV",
    });
    let config = {
      method: "post",
      url: urlEvent,
      headers: {
        "Content-Type": "application/json",
      },
      data: lsData,
    };
    axios(config)
      .then((response) => {
        //TODO - this is the response from database after logging event info.
      })
      .catch((error) => {
        //TODO - code to catch if there is exception logging event.
      });
  };

  // Calling address API end
  import(`../../styles/immunization.scss`);
  return (
    <Fragment>
      <Page {...state} callbackFn={callbackFn} logouturl={logouturl} isSecure={true} />
      {state.isLoading && (
        <div className="loader">
          <div className="loader-backdrop"></div>
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle
              className="loader-path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
            ></circle>
          </svg>
        </div>
      )}
    </Fragment>
  );

  // comment out event logging functions if not using or working
  function callbackFn(eventType, data) {
    let eventPerformed = ``;
    try {
      // let showError = getIsError();
      // if (!showError) {
      //   hideMessagePop();
      // }
      // logEvent("user01", eventType, "session001");
      updateActiveState(true);
    } catch (error) {
      //TODO - log error if needed in event logging
    }
    switch (eventType) {
      case "onPrimaryClickAddressVerificationModal":
        setUpdateAddress(data);
        let selectedAddress = state.data.pageSections.addressVerificationModal.data.content.data.items[state.data.pageSections.addressVerificationModal.data.content.data.value].displayName;
        if(selectedAddress){
          if(selectedAddress.split(" ").join("") === getAddress().split(" ").join("")){
            eventPerformed = `event performed : ${eventType} :: user selected entered address on address verification screen`;
            logEvent(username, eventPerformed, requestid);
            getMcirID(true);
          }else{
            eventPerformed = `event performed : ${eventType} :: user selected suggested address on address verification screen`;
            logEvent(username, eventPerformed, requestid);
            getMcirID(false);
          }
        }
        // getMcirID();
        uncheckConsent();
        break;
      case "onChangeConsent":
        eventPerformed = `event performed : ${eventType} :: user selected consent check box`;
        logEvent(username, eventPerformed, requestid);
        closeWarningModal();
        hideMessagePop();
        consentChanged(data);
        break;
      case "onChangeSsn":
        closeWarningModal();
        hideMessagePop();
        changeText("ssn", data);
        updateSsnNum(data.target.value);
        isNotValidSSN(data.target.value);
        break;
      case "onBlurSsn":
        //This is currently not used, code can be added if need be in future
        break;
      case "onChangePhone":
        closeWarningModal();
        hideMessagePop();
        changeText("phone", data);
        updatePhnNum(data.target.value);
        isNotValidPhn(data.target.value);
        break;
      case "onBlurPhone":
        //This is currently not used, code can be added if need be in future
        break;
      case "onUploadPhotoId":
        eventPerformed = `event performed : ${eventType} :: user uploaded an document`;
        logEvent(username, eventPerformed, requestid);
        resetImmunizationPage();
        resetSSNStatus();
        resetPHNStatus();
        hideMessagePop();
        onUploadingPhotoIdDoc(data);
        break;
      case "onBlurAddress":
        changeText("address", data);
        parseFullAddress(data.target.value);
        parseAddressFromAPI(data.target.value);
        updateAddress(data.target.value);
        break;
      case "onChangeAddress":
        changeText("address", data);
        parseFullAddress(data.target.value);
        updateAddress(data.target.value);
        break;
      case "onClickGetMyImmunization":
        eventPerformed = `event performed : ${eventType} :: user clicked on Get Immunization record`;
        logEvent(username, eventPerformed, requestid);
        if (getSSNStatus() || getPHNStatus()) {
          validateSSNandPhone(getSSNStatus(), getPHNStatus());
        } else {
          validateSSNandPhone(getSSNStatus(), getPHNStatus());
          if (
            state.data.pageSections.formContainer.data.gridItems.dataForm.data
              .formItem.data.gridItems.address.data.value
          ) {
            closeWarningModal();
            hideMessagePop();
            validateAddress();
          } else {
            warningMessagePopUp("error", "Please enter an address.");
          }
        }
        break;
      case "onCloseClickNoRecordFoundModal":
        eventPerformed = `event performed : ${eventType} :: user was shown No Records Found Pop-up`;
        logEvent(username, eventPerformed, requestid);
        hideNoRecordFoundModal();
        uncheckConsent();
        break;
      case "onPrimaryClickNoRecordFoundModal":
        eventPerformed = `event performed : ${eventType} :: user was shown No Records Found Pop-up`;
        logEvent(username, eventPerformed, requestid);
        hideNoRecordFoundModal();
        uncheckConsent();
        break;
      case "onCloseClickAddressVerificationModal":
        eventPerformed = `event performed : ${eventType} :: user closed address verification Pop-up`;
        logEvent(username, eventPerformed, requestid);
        hideAddressVerificationModal();
        break;
      case "onChangeAddressRadio":
        changeAddress(data);
        break;
      case "onCloseClickWarningModal":
        closeWarningModal();
        break;
      case "onChangeRecaptchaComponent":
        eventPerformed = `event performed : ${eventType} :: user selected reCaptcha check box`;
        logEvent(username, eventPerformed, requestid);
        recaptchaValidation(data);
        break;
      default:
        console.log(`no event type exists of name: ${eventType}`);
    }
  }

  function recaptchaValidation(data) {
    updateIsRecaptcha(true);
    let buttonDis = true;
    if (getConsent()) {
      buttonDis = false;
    }
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            formContainer: {
              ...prevState.data.pageSections.formContainer,
              data: {
                ...prevState.data.pageSections.formContainer.data,
                gridItems: {
                  ...prevState.data.pageSections.formContainer.data.gridItems,
                  dataForm: {
                    ...prevState.data.pageSections.formContainer.data.gridItems
                      .dataForm,
                    isHidden: false,
                    data: {
                      ...prevState.data.pageSections.formContainer.data
                        .gridItems.dataForm.data,
                      centerButton: {
                        ...prevState.data.pageSections.formContainer.data
                          .gridItems.dataForm.data.centerButton,
                        data: {
                          ...prevState.data.pageSections.formContainer.data
                            .gridItems.dataForm.data.centerButton.data,
                          isDisabled: buttonDis,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    });
  }

  function resetStateValues() {
    setState((prevState) => {
      return {
        ...prevState,
      };
    });
  }

  async function uncheckConsent() {
    if(state?.data?.pageSections?.formContainer?.data?.gridItems?.dataForm?.data?.formItem?.data?.gridItems?.data?.items){
      await setState((prevState) => {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            pageSections: {
              ...prevState.data.pageSections,
              formContainer: {
                ...prevState.data.pageSections.formContainer,
                data: {
                  ...prevState.data.pageSections.formContainer.data,
                  gridItems: {
                    ...prevState.data.pageSections.formContainer.data.gridItems,
                    dataForm: {
                      ...prevState.data.pageSections.formContainer.data.gridItems
                        .dataForm,
                      data: {
                        ...prevState.data.pageSections.formContainer.data
                          .gridItems.dataForm.data,
                        formItem: {
                          ...prevState.data.pageSections.formContainer.data
                            .gridItems.dataForm.data.formItem,
                          data: {
                            ...prevState.data.pageSections.formContainer.data
                              .gridItems.dataForm.data.formItem.data,
                            gridItems: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems,
                              consent: {
                                ...prevState.data.pageSections.formContainer.data
                                  .gridItems.dataForm.data.formItem.data.gridItems.consent,
                                data: {
                                  ...prevState.data.pageSections.formContainer.data
                                    .gridItems.dataForm.data.formItem.data.gridItems.data,
                                  items : {
                                      ...prevState.data.pageSections.formContainer.data
                                        .gridItems.dataForm.data.formItem.data.gridItems.data.items,
                                      isChecked: false
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        };
      });
    }
  }

  function isNotValidSSN(value) {
    let ssn = "";
    if (value) {
      ssn = value.split(" ").join("");
      const regEx = new RegExp("/^[0-9a-z]+$/");
      if (
        ssn &&
        ssn.length > 0 &&
        (regEx.test(ssn) ||
          ssn.length !== 9 ||
          ssn.substr(0, 3) === "000" ||
          ssn.substr(0, 3) === "666" ||
          ssn.substr(0, 1) === "9" ||
          ssn.substr(3, 2) === "00" ||
          ssn.substr(5, 4) === "0000")
      ) {
        updateSSNStatus(true);
      } else {
        updateSSNStatus(false);
      }
    } else {
      updateSSNStatus(false);
    }
  }

  function isNotValidPhn(value) {
    let phn = "";
    if (value) {
      phn = value.split(" ").join("");
      if (phn && phn.length > 2 && phn.length < 12) {
        updatePHNStatus(true);
      } else {
        updatePHNStatus(false);
      }
    } else {
      updatePHNStatus(false);
    }
  }

  function validateSSNandPhone(isSSNError, isPHNError) {
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            formContainer: {
              ...prevState.data.pageSections.formContainer,
              data: {
                ...prevState.data.pageSections.formContainer.data,
                gridItems: {
                  ...prevState.data.pageSections.formContainer.data.gridItems,
                  dataForm: {
                    ...prevState.data.pageSections.formContainer.data.gridItems
                      .dataForm,
                    data: {
                      ...prevState.data.pageSections.formContainer.data
                        .gridItems.dataForm.data,
                      formItem: {
                        ...prevState.data.pageSections.formContainer.data
                          .gridItems.dataForm.data.formItem,
                        data: {
                          ...prevState.data.pageSections.formContainer.data
                            .gridItems.dataForm.data.formItem.data,
                          gridItems: {
                            ...prevState.data.pageSections.formContainer.data
                              .gridItems.dataForm.data.formItem.data.gridItems,
                            ssn: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .ssn,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.ssn.data,
                                error: isSSNError,
                              },
                            },
                            phone: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .phone,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.phone.data,
                                error: isPHNError,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    });
    if (isSSNError) {
      warningMessagePopUp("error", "Please enter a valid SSN.");
    }
    if (isPHNError) {
      warningMessagePopUp("error", "Please enter a valid Phone number.");
    }
  }

  function hideAddressVerificationModal() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            addressVerificationModal: {
              ...prevState.data.pageSections.addressVerificationModal,
              data: {
                ...prevState.data.pageSections.addressVerificationModal.data,
                isOpen: false,
              },
            },
          },
        },
      };
    });
  }

  function warningMessagePopUp(errType, errorMessage) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            validationError: {
              ...prevState.data.pageSections.validationError,
              data: {
                ...prevState.data.pageSections.validationError.data,
                gridItems: {
                  ...prevState.data.pageSections.validationError.data.gridItems,
                  alerts: {
                    ...prevState.data.pageSections.validationError.data
                      .gridItems.alerts,
                    data: {
                      ...prevState.data.pageSections.validationError.data
                        .gridItems.alerts.data,
                      type: errType,
                      subHeading: errorMessage,
                      isOpen: true,
                    },
                  },
                },
              },
            },
          },
        },
      };
    });
  }

  async function hideMessagePop() {
    await setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            validationError: {
              ...prevState.data.pageSections.validationError,
              data: {
                ...prevState.data.pageSections.validationError.data,
                gridItems: {
                  ...prevState.data.pageSections.validationError.data.gridItems,
                  alerts: {
                    ...prevState.data.pageSections.validationError.data
                      .gridItems.alerts,
                    data: {
                      ...prevState.data.pageSections.validationError.data
                        .gridItems.alerts.data,
                      isOpen: false,
                    },
                  },
                },
              },
            },
          },
        },
      };
    });
  }

  function changeText(key, data) {
    let tarVaue = data.target.value;
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            formContainer: {
              ...prevState.data.pageSections.formContainer,
              data: {
                ...prevState.data.pageSections.formContainer.data,
                gridItems: {
                  ...prevState.data.pageSections.formContainer.data.gridItems,
                  dataForm: {
                    ...prevState.data.pageSections.formContainer.data.gridItems
                      .dataForm,
                    isHidden: false,
                    data: {
                      ...prevState.data.pageSections.formContainer.data
                        .gridItems.dataForm.data,
                      formItem: {
                        ...prevState.data.pageSections.formContainer.data
                          .gridItems.dataForm.data.formItem,
                        data: {
                          ...prevState.data.pageSections.formContainer.data
                            .gridItems.dataForm.data.formItem.data,
                          gridItems: {
                            ...prevState.data.pageSections.formContainer.data
                              .gridItems.dataForm.data.formItem.data.gridItems,
                            [key]: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data
                                .gridItems[key],
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems[key].data,
                                value: tarVaue,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    });
  }

  function consentChanged(data) {
    updateConsent(data.consentCheckbox);
    let buttonDis = true;
    if (data.consentCheckbox && getIsRecaptcha()) {
      buttonDis = false;
    }
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            formContainer: {
              ...prevState.data.pageSections.formContainer,
              data: {
                ...prevState.data.pageSections.formContainer.data,
                gridItems: {
                  ...prevState.data.pageSections.formContainer.data.gridItems,
                  dataForm: {
                    ...prevState.data.pageSections.formContainer.data.gridItems
                      .dataForm,
                    isHidden: false,
                    data: {
                      ...prevState.data.pageSections.formContainer.data
                        .gridItems.dataForm.data,
                      centerButton: {
                        ...prevState.data.pageSections.formContainer.data
                          .gridItems.dataForm.data.centerButton,
                        data: {
                          ...prevState.data.pageSections.formContainer.data
                            .gridItems.dataForm.data.centerButton.data,
                          isDisabled: buttonDis,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    });
  }

  function closeWarningModal() {
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            warningModal: {
              ...prevState.data.pageSections.warningModal,
              data: {
                ...prevState.data.pageSections.warningModal.data,
                isOpen: false,
              },
            },
          },
        },
      };
    });
  }

  function setUpdateAddress() {
    let tempAddress =
      state.data.pageSections.addressVerificationModal.data.content.data.items[
        state.data.pageSections.addressVerificationModal.data.content.data.value
      ].displayName;
    // parseFullAddress(tempAddress)
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            addressVerificationModal: {
              ...prevState.data.pageSections.addressVerificationModal,
              data: {
                ...prevState.data.pageSections.addressVerificationModal.data,
                hasClose: true,
                isOpen: false,
              },
            },
            formContainer: {
              ...prevState.data.pageSections.formContainer,
              data: {
                ...prevState.data.pageSections.formContainer.data,
                gridItems: {
                  ...prevState.data.pageSections.formContainer.data.gridItems,
                  dataForm: {
                    ...prevState.data.pageSections.formContainer.data.gridItems
                      .dataForm,
                    isHidden: false,
                    data: {
                      ...prevState.data.pageSections.formContainer.data
                        .gridItems.dataForm.data,
                      formItem: {
                        ...prevState.data.pageSections.formContainer.data
                          .gridItems.dataForm.data.formItem,
                        data: {
                          ...prevState.data.pageSections.formContainer.data
                            .gridItems.dataForm.data.formItem.data,
                          gridItems: {
                            ...prevState.data.pageSections.formContainer.data
                              .gridItems.dataForm.data.formItem.data.gridItems,
                            address: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .address,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.address.data,
                                value: tempAddress,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    });
  }

  function changeAddress(data) {
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            addressVerificationModal: {
              ...prevState.data.pageSections.addressVerificationModal,
              data: {
                ...prevState.data.pageSections.addressVerificationModal.data,
                content: {
                  ...prevState.data.pageSections.addressVerificationModal.data
                    .content,
                  data: {
                    ...prevState.data.pageSections.addressVerificationModal.data
                      .content.data,
                    value: data.target.value,
                  },
                },
              },
            },
          },
        },
      };
    });
  }

  function showNoRecordFoundModal() {
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            noRecordFoundModal: {
              ...prevState.data.pageSections.noRecordFoundModal,
              data: {
                ...prevState.data.pageSections.noRecordFoundModal.data,
                isOpen: true,
              },
            },
          },
        },
      };
    });
  }

  function hideNoRecordFoundModal() {
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            noRecordFoundModal: {
              ...prevState.data.pageSections.noRecordFoundModal,
              data: {
                ...prevState.data.pageSections.noRecordFoundModal.data,
                isOpen: false,
              },
            },
          },
        },
      };
    });
  }

  function existingAddress() {
    setState((prevState) => {
      return {
        ...prevState,
        isLoading: false,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            addressVerificationModal: {
              ...prevState.data.pageSections.addressVerificationModal,
              data: {
                ...prevState.data.pageSections.addressVerificationModal.data,
                isOpen: true,
                content: {
                  ...prevState.data.pageSections.addressVerificationModal.data
                    .content,
                  data: {
                    ...prevState.data.pageSections.addressVerificationModal.data
                      .content.data,
                    items: {
                      ...prevState.data.pageSections.addressVerificationModal
                        .data.content.data.items,
                      enteredAddress: {
                        ...prevState.data.pageSections.addressVerificationModal
                          .data.content.data.items.enteredAddress,
                        displayName:
                          state.data.pageSections.formContainer.data.gridItems
                            .dataForm.data.formItem.data.gridItems.address.data
                            .value,
                        isChecked: true,
                      },
                      suggestedAddress: {
                          ...prevState.data.pageSections.addressVerificationModal
                            .data.content.data.items.suggestedAddress,
                          displayName: "",
                          isChecked: true,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    });
  }

  function closeAddressVerification() {
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            addressVerificationModal: {
              ...prevState.data.pageSections.addressVerificationModal,
              data: {
                ...prevState.data.pageSections.addressVerificationModal.data,
                isOpen: false,
              },
            },
          },
        },
      };
    });
  }

  // Calling address API start
  function addressVerification(valueAddress) {
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            addressVerificationModal: {
              ...prevState.data.pageSections.addressVerificationModal,
              data: {
                ...prevState.data.pageSections.addressVerificationModal.data,
                isOpen: true,
                content: {
                  ...prevState.data.pageSections.addressVerificationModal.data
                    .content,
                  data: {
                    ...prevState.data.pageSections.addressVerificationModal.data
                      .content.data,
                    items: {
                      ...prevState.data.pageSections.addressVerificationModal
                        .data.content.data.items,
                      enteredAddress: {
                        ...prevState.data.pageSections.addressVerificationModal
                          .data.content.data.items.enteredAddress,
                        displayName:
                          state.data.pageSections.formContainer.data.gridItems
                            .dataForm.data.formItem.data.gridItems.address.data
                            .value,
                        isChecked: true,
                      },
                      suggestedAddress: {
                        ...prevState.data.pageSections.addressVerificationModal
                          .data.content.data.items.suggestedAddress,
                        displayName: valueAddress,
                        isChecked: false,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    });
  }

  function updateFieldsAfterDocUpload(
    tempfstName,
    tempmdlName,
    templstName,
    tempgender,
    tempdobDate,
    tempdobDateMPI,
    tempdocExpDate,
    tempdocNumber,
    tempaddress,
    tempRegion
  ) {
    setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          pageSections: {
            ...prevState.data.pageSections,
            formContainer: {
              ...prevState.data.pageSections.formContainer,
              data: {
                ...prevState.data.pageSections.formContainer.data,
                gridItems: {
                  ...prevState.data.pageSections.formContainer.data.gridItems,
                  dataForm: {
                    ...prevState.data.pageSections.formContainer.data.gridItems
                      .dataForm,
                    isHidden: false,
                    data: {
                      ...prevState.data.pageSections.formContainer.data
                        .gridItems.dataForm.data,
                      formItem: {
                        ...prevState.data.pageSections.formContainer.data
                          .gridItems.dataForm.data.formItem,
                        data: {
                          ...prevState.data.pageSections.formContainer.data
                            .gridItems.dataForm.data.formItem.data,
                          gridItems: {
                            ...prevState.data.pageSections.formContainer.data
                              .gridItems.dataForm.data.formItem.data.gridItems,
                            firstname: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .firstname,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.firstname.data,
                                value: tempfstName,
                              },
                            },
                            middlename: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .middlename,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.middlename.data,
                                value: tempmdlName,
                              },
                            },
                            lastname: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .lastname,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.lastname.data,
                                value: templstName,
                              },
                            },
                            sex: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .sex,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.sex.data,
                                value: tempgender,
                              },
                            },
                            dob: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .dob,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.dob.data,
                                value: tempdobDate,
                              },
                            },
                            docnumber: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .docnumber,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.docnumber.data,
                                value: tempdocNumber,
                              },
                            },
                            expiration: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .expiration,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.expiration.data,
                                value: tempdocExpDate,
                              },
                            },
                            issued: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .issued,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.issued.data,
                                value: tempRegion,
                              },
                            },
                            address: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .address,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.address.data,
                                value: tempaddress,
                              },
                            },
                            ssn: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .ssn,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.ssn.data,
                                value: "",
                              },
                            },
                            phone: {
                              ...prevState.data.pageSections.formContainer.data
                                .gridItems.dataForm.data.formItem.data.gridItems
                                .phone,
                              data: {
                                ...prevState.data.pageSections.formContainer
                                  .data.gridItems.dataForm.data.formItem.data
                                  .gridItems.phone.data,
                                value: "",
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    });
  }

  function resetImmunizationPage() {
    // clearDemographics();
    uncheckConsent();
    resetFstName();
    resetMdlName();
    resetLstName();
    resetSuffix();
    resetGender();
    resetDobDate();
    resetDobDateMPI();
    resetDocExpDate();
    resetDocExpDateMPI();
    resetDocNumber();
    resetDocType();
    resetSsnNum();
    resetPhnNum();
    resetAddress();
    resetParsedStreet();
    resetParsedCity();
    resetParsedState();
    resetParsedZip();
    resetParsedCountry();
    // setState(immunization);
  }
}