import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/styles";
import InputMask from "react-input-mask";

import { Capitalize } from "../../utils/capitalize";

const useStyles = makeStyles((theme) => ({
  label: { marginBottom: "0.5rem", fontSize: ".875rem" },
  helpText: { marginLeft: 0 },
  labelHidden: { visibility: "hidden" },
  asterik: {
    fontSize: "1.5rem",
    color: theme.palette.common.danger,
    lineHeight: "1rem",
  },
}));

export default function CustomInput(props) {
  const { data, componentNumber, callbackFn } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `input-${componentNumber}`,
    name: `input-${componentNumber}`,
    label: "",
    classes: "",
    error: false,
    placeholder: "",
    helpText: "",
    value: "",
    isDisabled: false,
    isFullWidth: true,
    isRequired: false,
    isLabelHidden: false,
    variant: "outlined",
    contentType: "text",
    minlength: 0,
    maxlength: 0,
  };
  const config = { ...defaultProps, ...data };
  const capitalId = Capitalize(config.id);
  config.helpTextId = `helper-text-${config.id}`;
  config.labelHiddenClass = config.isLabelHidden ? classes.labelHidden : "";
  config.events = {
    onChangeEvent: `onChange${capitalId}`,
    onBlurEvent: `onBlur${capitalId}`
  };

  function handleOnChange(event) {
    callbackFn(config.events.onChangeEvent, event);
  }

  function handleOnBlur(event) {
    callbackFn(config.events.onBlurEvent, event);
  }

  return (
    <FormControl
      fullWidth={config.isFullWidth}
      className={config.classes + data.isRequired}
    >
      {config.label && (
        <label
          htmlFor={config.id}
          className={`${classes.label} ${config.labelHiddenClass}`}
        >
          {config.label}{" "}
          {config.isRequired && <sub className={classes.asterik}>*</sub>}
        </label>
      )}
      {config.mask && (
        <InputMask
          mask={config.mask}
          value={config.value}
          maskChar=" "
          disabled={config.isDisabled}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          inputProps={{
            minLength: config.minlength ? config.minlength : "",
            maxLength: config.maxlength ? config.maxlength : "",
          }}
        >
          {() => (
            <OutlinedInput
              disabled={config.isDisabled}
              type={config.contentType === "password" ? "password" : "text"}
              id={config.id}
              name={config.name}
              error={config.error}
              variant={config.variant}
              aria-describedby={config.helpTextId}
              required={config.isRequired}
              placeholder={config.placeholder}
            />
          )}
        </InputMask>
      )}
      {!config.mask && (
        <OutlinedInput
          disabled={config.isDisabled}
          type={config.contentType === "password" ? "password" : "text"}
          id={config.id}
          name={config.name}
          value={config.value}
          variant={config.variant}
          error={config.error}
          aria-describedby={config.helpTextId}
          required={config.isRequired}
          placeholder={config.placeholder}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          inputProps={{
            minLength: config.minlength ? config.minlength : "",
            maxLength: config.maxlength ? config.maxlength : "",
          }}
        />
      )}

      {config.helpText && (
        <FormHelperText id={config.helpTextId} className={classes.helpText}>
          {config.helpText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
