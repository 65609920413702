import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";
import { Typography, Box } from "@material-ui/core";

export default function CustomList(props) {
  const { data, componentNumber } = props;

  const defaultProps = {
    id: `list-${componentNumber}`,
    classes: "",
    listItems: [
      {
        href: "/",
        text: "",
      },
      {
        text: "",
      },
    ],
    isDisableGutters: false,
    isDivider: false,
    isNumbered: false
  };

  const config = { ...defaultProps, ...data };

  return (
    <List className={config.classes}>
      {config.listItems.map((listItem, index) => {
        return (
          <ListItem
            key={index}
            disableGutters={config.isDisableGutters}
            divider={config.isDivider && index !== config.listItems.length - 1}
            style={{ display: 'flex', alignItems: 'flex-start' }} // Flexbox layout for alignment
          >
            {config.isNumbered && (
              <Box component="span" style={{ marginRight: '8px', flexShrink: 0 }}>
                <Typography component="span" style={{ verticalAlign: 'top' }}>
                  {index + 1}.
                </Typography>
              </Box>
            )}

            <Box component="span">
              {listItem.parts ? (
                listItem.parts.map((part, partIndex) => (
                  <React.Fragment key={partIndex}>
                    {part.href ? (
                      <React.Fragment>
                        <Link href={part.href}>{part.text}</Link>
                        {partIndex < listItem.parts.length - 1 && <>&nbsp;</>}
                      </React.Fragment>
                    ) : (
                      <Typography
                        component="span"
                        style={{ fontWeight: part.bold ? 'bold' : 'normal' }}
                      >
                        {part.text}
                        {partIndex < listItem.parts.length - 1 && <>&nbsp;</>}
                      </Typography>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <Typography>{listItem.text}</Typography>
              )}
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
}