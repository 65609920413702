import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  seprator: {
    color: theme.palette.primary.main,
  },
  active: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

export default function CustomBreadcrumb(props) {
  const { data, componentNumber } = props;
  const classes = useStyles();
  const defaultProps = {
    id: `breadcrumb-${componentNumber}`,
    classes: "",
    items: [
      {
        link: "",
        name: "",
      },
      {
        name: "",
      },
    ],
    ariaLabel: "breadcrumb",
  };
  const config = { ...defaultProps, ...data };

  return (
    <Breadcrumbs
      aria-label={config.ariaLabel}
      id={config.id}
      className={config.classes}
      classes={{ separator: classes.seprator }}
    >
      {config.items.map((item, index) => {
        return (
          <div key={index}>
            {item.link && <Link href={item.link}>{item.name}</Link>}
            {!item.link && (
              <Typography className={classes.active}>{item.name}</Typography>
            )}
          </div>
        );
      })}
    </Breadcrumbs>
  );
}
